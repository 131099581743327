.exchange-v2.theme--light {
  .position-share-modal {
    .Modal-content {
      .Modal-body {
        *:not(.pnl, .checkbox-item, .price, .side) {
          color: #fff !important;
        }
      }
    }
  }

  .position-share-modal .actions {
    img {
    }
  }

  img.img-check {
  }

  .position-share-modal button,
  .position-share-modal a {
  }

  .position-share-modal button {
  }
  .tweet-link-container {
    a {
    }
  }
  .position-share-modal button:not(:disabled):hover,
  .position-share-modal a:not(:disabled):hover {
  }

  .position-share-modal button:active,
  .position-share-modal a:active {
  }

  .position-share-modal .icon {
  }

  .position-share {
  }

  .position-share .logo_wrapper {
  }

  .position-share .logo {
  }

  .position-share .chain_img {
  }

  .position-share .info {
  }

  .position-share .info li {
  }

  .position-share .info > .side.long {
    color: var(--Primary, #1eb2fc) !important;
  }

  .position-share .info > .side.short {
    color: #e43e53 !important;
  }

  .position-share .pnl {
    .change-dollar {
    }
  }

  .position-share .pnl.positive {
  }

  .position-share .pnl.negative {
  }

  .tweet-link-container {
  }

  .position-share .prices {
    > div {
      &:first-child {
      }

      p {
      }
    }
  }

  .position-share .prices p.price {
    color: #ffdf76 !important;
  }

  .position-share .referral-code {
  }

  .position-share .referral-code div {
  }

  .position-share .referral-code .label {
  }

  .position-share .referral-code .referral-code-info {
    .label {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6)) !important;
    }
  }

  .position-share .referral-code img {
  }

  .position-share .referral-code p {
  }

  .position-share .code {
  }

  .tweet-link-container.disabled {
  }

  .position-share-modal .App-button-option {
  }

  a.App-button-option.tweet-link {
  }

  a.App-button-option.tweet-link.disabled {
  }

  .image-loading {
  }

  .image-overlay-wrapper {
  }

  .image-overlay-wrapper .loading-text {
  }

  .image-overlay {
  }

  .image-overlay .spinning-loader {
  }

  .position-share .blue {
  }

  .position-share .red {
  }

  .checkbox-list > .checkbox-item {
    color: var(--Text-Text_Primary, #0d1a16) !important;
  }
}
