.orderbook-container {
  font-family: Inter;
  color: #fff;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
  width: 300px;

  // margin-top: 59px;
  padding-bottom: 16px;
  padding-top: 16px;
  border-left: 1px solid #2B3139;
  background: #181a20;
  @media (max-width: 1023px) {
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 0 16px;

  .tab-header {
    display: flex;
    gap: 16px;
    font-weight: bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;

    span {
      color: #848e9c;
      cursor: pointer;

      &:hover {
        color: #1eb2fc !important;
      }
    }

    .active {
      color: #1eb2fc !important;
    }
  }

  .ant-select {
    span {
      font-size: 12px !important;
      font-weight: 700 !important;
    }
  }
}

.orderbook-line {
  width: 100%;
  cursor: pointer;
  // background: #271416 !important;
}

.order-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 14px;
  padding: 0 16px;

  .order-value {
    flex: 1;
    color: #848e9c;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .end-text {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.orderbook-body {
  max-height: 500px;
  overflow-y: auto;

  // background-color: #111;
  @media (max-width: 1023px) {
    max-height: 450px;
  }
}

.orderbook-body-last-trade {
  padding-bottom: 18px;
}

.order-row {
  display: flex;
  gap: 4px;
  cursor: pointer;
  position: relative;
  padding: 0 16px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    // border-radius: 2px;
  }

  div {
    padding: 4px 0px;
    flex: 1;
    font-weight: 400;
  }
}

.asks {
  display: flex;
  flex-direction: column;
  gap: 1px;

  .has-order-book {
    position: absolute;
    top: 10px;
    left: 6px;
    background: #e43e53 !important;
    width: 4px !important;
    height: 4px !important;
    border-radius: 4px !important;
    padding: 0px !important;
    flex: none !important;
  }
}

.bids {
  display: flex;
  flex-direction: column;
  gap: 1px;

  .has-order-book {
    position: absolute;
    top: 10px;
    left: 6px;
    background: #0ecb81 !important;
    width: 4px !important;
    height: 4px !important;
    border-radius: 4px !important;
    padding: 0px !important;
    flex: none !important;
  }
}

.buy-trade {
  color: #0ecb81;
}

.sell-trade {
  color: #e43e53;
}

.price {
  color: #e43e53;
  min-width: 80px;
}

.timestamp {
  color: white;
}

.bid .price {
  color: #0ecb81;
}

.qty,
.total {
  text-align: right;
  min-width: 60px;
}

.current-price {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 8px 16px;

  div {
    flex: 1;
  }

  .last-price {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  .mark-price {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    color: #848e9c;
  }

  .funding-rate {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #848e9c;
  }
}

.current-price .icon {
  margin-right: 4px;
}

.current-price .percentage-change {
  color: #fff;
  font-size: 10px;
  opacity: 0.7;
}

.current-price {
  .Tooltip-popup {
    width: 100px !important;
    font-weight: 400 !important;
  }
}
