.PositionDropdown-dots-icon {
  font-size: 1rem;
  border: none;

  display: inline-flex;

  margin-top: 0.3rem;
  background: rgba(255, 255, 255, 0);
  border-radius: 4px;
  padding: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
}

.PositionDropdown-dots-icon:hover {
  color: rgba(255, 255, 255, 1);
  background: #262933;
}

.PositionDropdown-extra-options {
  position: relative;
}

.PositionDropdown-extra-options .menu-items {
  top: 1rem;
  min-width: 15.5rem;
}
