.App-header-network {
  position: relative;
  margin-left: 12px;
  border: 1px solid #ffffff1a;
  border-radius: var(--border-radius-sm);
  height: 4rem;
  display: inline-flex;
  align-items: center;
  color: white;
  border-radius: 4px;
}

.App-header-language {
  margin-left: 0;
}

.network-dropdown {
  display: inline-flex;
}
.network-dropdown-items {
  min-width: 17rem;
  /* padding-bottom: 0.8rem; */
}
.network-dropdown-items:last-child {
  /* padding-bottom: 0; */
}

.network-dropdown-divider {
  margin-bottom: 0.8rem;
  border-bottom: 1px solid #ffffff29;
}
.network-dropdown:not(.disabled):hover {
  background: var(--dark-blue-hover);
  cursor: pointer;
}

.network-dropdown > button {
  height: 3.6rem;
  background: transparent;
  border-radius: 0.4rem;
  border: none;
  pointer-events: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
}

.network-dropdown-icon {
  width: 20px;
  height: 20px;
  /* border-radius: 50%; */
}

.language-popup .Modal-content {
  width: 32rem;
}

.network-dropdown-seperator {
  width: 1px;
  background: var(--dark-blue-border);
  margin: 0.8rem 0;
}

.menu-item-group {
  display: flex;
  align-items: center;
}

.menu-item-group.disabled {
  opacity: 0.5;
}

.network-dropdown-menu-item {
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: space-between;
}
.last-dropdown-menu {
  margin-bottom: 0.8rem;
}
.network-popup .Modal-content {
  width: 100%;
  max-width: 29.6rem;
  margin: auto;
}
.network-option {
  cursor: pointer;
  background: var(--dark-blue);
  /* border: 1px solid var(--dark-blue-border); */
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}
.network-option:last-child {
  margin-bottom: 0;
}

.network-option img {
  width: 2.5rem;
  margin-right: 1.5rem;
}

.network-option span {
  font-size: var(--font-base);
  letter-spacing: 0.29px;
  color: #fff;
}

.network-option-img-label {
  font-size: var(--font-md);
  line-height: 2rem;
  letter-spacing: 0.29px;
  color: #fff;
}

.dropdown-label {
  color: #a0a3c4;
  padding: 0.8rem;
  font-size: 1.25rem;
}

.language-popup .Modal-body {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.language-modal-item {
  border: 1px solid var(--dark-blue-border);
  cursor: pointer;
}

.language-modal-item.active {
  border: 1px solid #a1a3c4;
}

.menu-item-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
}

.network-dropdown-headings {
  padding: 0.8rem 0.8rem 0.4rem 0.8rem;
  opacity: 0.7;
  font-size: 1.25rem;
}

.network-dropdown-item-label {
  margin-left: 12px;
}

.network-dropdown-list {
  display: grid;
  grid-template-columns: 1fr;
}

.menu-item:hover .menu-item-label {
  color: #eee;
}

.active-dot {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
}
.active-dot.Arbitrum {
  background-color: #4275a8;
}
.active-dot.Optimism {
  background-color: #ff1420;
}
.active-dot.Fantom {
  background-color: #12b5ec;
}
.network-dropdown-label {
  font-size: 1.25rem;
  margin-bottom: 0.8rem;
  opacity: 0.7;
}

.more-options {
  margin-top: 1rem;
}

.menu-items-wrap {
  padding: 8px;
  border-radius: 4px;
}

.network-dropdown-items .label {
  color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
  font-size: 12px;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}
.theme--light .network-dropdown-items .label {
  color: rgba(13, 26, 22, 0.7);
}
.network-dropdown-items .radio-input {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #02b27f;
  cursor: pointer;
  position: relative;
}

.network-dropdown-items .divider {
  margin: 0;
}

.network-dropdown-items .radio-input::after {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 50%;
}

.network-dropdown-items .radio-input.active::after {
  background: #02b27f;
}

.network-dropdown-menu-item:not(:has(.radio-input.active)):hover .radio-input::after {
  background: #02b27f99;
}

.network-dropdown-items .menu-item-group {
  width: 100%;
}

.network-dropdown-items .network-dropdown-item-label:has(.radio-input) {
  display: flex;
  align-items: center;
  gap: var(--Base-Unit-XS-8, 8px);
  align-self: stretch;
  justify-content: space-between;
  flex: 1;
}

@media (max-width: 1033px) {
  .App-header-network {
    margin-left: 1.6rem;
  }
  .App-header-language {
    margin-left: 0;
  }
}
@media (max-width: 450px) {
  .App-header-network {
    margin-left: 0.6rem;
  }
  .App-header-language {
    margin-left: 0;
  }
  .network-dropdown > button {
    padding: 0 0.5rem;
  }
  .network-dropdown.homepage-header > button {
    padding: 0 0.8rem;
  }
}
