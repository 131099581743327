:root {
  --text-secondary: #848e9c;
}

.Exchange-updated-ui {
  margin: 0;
  width: 100%;
  max-width: 100%;
  padding-top: 15px !important;
  padding-bottom: 0 !important;

  .positionHistory-modal {
    .Exchange-info-row {
      & + .Exchange-info-row {
        margin-top: 8px;
      }
    }
  }

  .Exchange-content {
    padding-left: 0;
    padding-right: 0;
    grid-gap: 0;
    padding-bottom: 0;

    // height: calc(100vh - 62px) !important;
    .ExchangeChart-top {
      border-radius: 0;
    }

    .ExchangeChart-top {
      border-bottom: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
      background: none;
    }

    .swap-box-v2 {
      border-radius: 0;
      margin-bottom: 0;
      background: none;
      border-bottom: 1px solid rgba(55, 63, 92, 0.5);
      padding: 0;
    }

    .ExchangeChart-bottom {
      top: 76px;
      border-radius: 0;
    }

    .App-box-highlight {
      border-radius: 0;
    }

    .Exchange-swap-box {
      // min-height: calc(100vh - 62px) !important;
      height: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 700px) {
        min-height: unset !important;
      }
    }

    .info-main-price {
      .deltaChange {
        display: none;
      }
    }

    @media screen and (max-width: 1110px) {
      padding-bottom: 46px;

      .Exchange-lists {
        padding-left: 16px;
        padding-right: 16px;
      }

      .Exchange-should-show-position-lines {
        display: none;
      }

      .ExchangeChart-top-inner {
        .ExchangeChart-title {
          margin-bottom: 0;
        }

        .changed-24h {
          display: none;
        }

        // .info-main-price {
        //   position: absolute;
        //   right: 16px;
        //   top: 22px;
        //   text-align: right !important;

        //   .deltaChange {
        //     display: inline-block;
        //   }

        //   .ExchangeChart-info-label {
        //     display: none;
        //   }
        // }

        .ExchangeChart-additional-info {
          width: 45%;
          margin-top: 16px;

          // @media screen and (max-width: 700px) {
          //   width: auto;
          // }
        }
      }
    }

    @media screen and (max-width: 700px) {
      .ExchangeChart-top-inner {
        gap: 0 !important;
      }

      .Exchange-left {
        .ExchangeChart-top {
          padding-top: 0 !important;
        }
      }

      .info-main-price {
        top: 0px !important;
        text-align: left;
      }

      .Exchange-right {
        padding-left: 16px;
        padding-right: 16px;

        .Exchange-swap-box {
          border-radius: 16px !important;
        }
      }

      .ExchangeChart-top {
        border-bottom: none !important;
      }

      .exchange-section-first {
        margin-bottom: 16px !important;
      }

      .Exchange-swap-ball {
        top: -28px;
      }
    }
  }

  .number-tag {
    display: flex;
    width: 20px;
    height: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    font-size: 10px;
    border-radius: var(--Base-Unit-XS-8, 8px);
    border: 1px solid #41454d;
    background: var(--Nature-2, #1a1d26);
  }

  .Exchange-lists {
    .Exchange-list-tabs {
      border-radius: 4px;
      display: flex;

      // border: 1px solid var(--Border, rgba(55, 63, 92, 0.50));

      .Tab-option {
        // border-radius: 4px;
        // font-style: normal;
        // margin-right: 0px;
        // flex-direction: column;
        // font-style: normal;

        display: flex;
        // padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        color: var(--text-secondary);
        font-size: 12px;
        font-weight: 700;
        line-height: 140%;
        // border-radius: 16px 16px 0px 0px !important;
        /* 16.8px */

        span {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }

      .active {
        color: #1EB2FC !important;

        // border-bottom: 2px solid var(--Primary, #0ECB81);
        // background: var(--Nature-2, #1a1d26);

        .number-tag {
          color: white !important;
        }
      }
    }

    .main-wrapper {
      width: 100%;
      max-width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.pad-chart-container {
  display: none;
  z-index: 100;

  @media screen and (max-width: 1110px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #090a11;
    /* White/10 */
    border-top: 1px solid rgba(55, 63, 92, 0.5);
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.25);

    // height: 48px;
    .show-ui-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 16px;

      .chart-tick-container {
        display: flex;
        align-items: center;
        gap: 16px;

        .view-chart {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .chart-positions {
          position: relative;
          top: -2px;
          opacity: 1;
        }
      }

      .index-chart {
        img {
          position: relative;
          top: -2px;
        }
      }
    }

    iframe {
      height: 30vh !important;
    }
  }

  @media screen and (max-width: 700px) {
    iframe {
      height: 40vh !important;
    }
  }
}

.chart-pad-show-content {
  @media screen and (max-width: 1110px) {
    padding-bottom: calc(30vh + 55px) !important;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: calc(40vh + 65px) !important;
  }
}

.close-container {
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: flex-end;
}

.rc-slider-track {
  height: 8px !important;
}

.rc-slider-rail {
  height: 8px !important;
}

.rc-slider-dot {
  width: 2px !important;
  height: 12px !important;
  top: -2px !important;

  &:first-child {
    left: 1.9px !important;
  }
}

.rc-slider-handle {
  top: 5px !important;
}

.trade-v1 {
  @media screen and (max-width: 1350px) {
    height: unset !important;

    .Exchange-content {
      height: unset !important;

      .Exchange-lists {
        padding-top: 16px;
      }

      .Exchange-list {
        .App-card-options {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }
  }
}

.exchange-v1 {
  // .Exchange-content {
  //   height: unset !important;
  // }
  // .Exchange-right {
  //   @media screen and (max-width: 1350px) {
  //     overflow-y: unset !important;
  //     max-height: unset !important;
  //   }
  // }
  .ExchangeChart-additional-info {
    div {
      font-weight: 700;
    }

    .ExchangeChart-info-label {
      font-weight: 400;
    }
  }

  .info-main-price {
    .ExchangeChart-info-label {
      font-weight: 700;
    }
  }

  .trade-version {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ExchangeChart-top {
    @media screen and (max-width: 1110px) {
      padding-top: 12px !important;
      padding-bottom: 16px !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
    }

    @media screen and (max-width: 700px) {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }

  .Exchange-left {
    @media screen and (max-width: 700px) {
      padding-right: 8px !important;
      padding-left: 8px !important;
    }
  }

  .ExchangeChart-top-inner {
    @media screen and (max-width: 1400px) {
      gap: 24px;
    }
  }

  .chart-token-menu-items.menu-items {
    width: 20rem;
    // top: 5rem;
    right: unset;
    left: 0;
    background: #242743;
    border-radius: 4px;
  }

  .ExchangeChart-top {
    display: flex;

    @media screen and (max-width: 1350px) {
      flex-direction: column;
      gap: 12px;

      .ExchangeChart-top-inner {
        gap: 0;
      }
    }
  }

  .Exchange-swap-box {
    .Exchange-swap-order-type-tabs {
      background-color: #1a1d26;
      border-radius: 100px;
      display: flex;

      .Tab-option {
        border-radius: 100px;
        font-style: normal;
        margin-right: 0px;
        flex-direction: column;
        font-style: normal;

        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;

        color: var(--Text-Text_Secondary, #848e9c);
        font-size: 12px;
        font-weight: 700;
        line-height: 140%;
        /* 16.8px */
      }

      .active {
        color: #0ecb81 !important;
        border-radius: 100px;
        border: none;
        background: var(--Nature-3, #262933);
      }
    }
  }

  .Exchange-swap-section {
    border-radius: 4px !important;
    padding: 12px;

    transition: all 150ms ease-in-out;
    /* box-shadow: inset 0 0 3rem 0.5rem hsl(0deg 0% 100% / 1%); */
  }

  .Exchange-swap-section:has(input:focus) {
    background: rgba(0, 0, 0, 1);
    // border: 1px solid #c1ff99;
  }

  .Exchange-swap-box-inner {
    .Exchange-info-row {
      .align-right {
        font-weight: 700 !important;
      }
    }
  }
}

.max-leverage-container {
  .chart-token-selector--current {
    width: 100px;
  }

  .token-selected {
    width: 24px;
    height: 24px;
  }
}

#webpack-dev-server-client-overlay {
  display: none;
}

.swap-box-swap-container {
  border-left: 1px solid rgba(255, 255, 255, 0.1);

  .swap-box-v2 {
    .Tab {
      margin-bottom: 1px !important;
      height: 44px;
    }
  }
}

.exchange-v1 {
  height: calc(100vh - 62px);

  .Exchange-swap-box {
    flex: 1;
    height: unset !important;
  }

  .Modal-content {
    .Tooltip-popup {
      width: 310px !important;
    }
  }

  @media screen and (max-width: 700px) {
    height: unset;
  }

  .Exchange-content {
    height: calc(100vh - 62px);

    @media screen and (max-width: 1350px) {
      height: calc(100vh - 62px);

      .Exchange-swap-box {
        height: calc(100vh - 62px);
      }
    }

    .box-trade-swap {
      height: calc(100vh - 62px);

      @media screen and (max-width: 1350px) {
        height: calc(100vh - 109px);
      }

      @media screen and (max-width: 700px) {
        height: unset;
      }
    }

    @media screen and (max-width: 700px) {
      height: unset;

      .Exchange-swap-box {
        height: unset;
      }
    }
  }

  .Exchange-left {
    overflow-y: auto;
    max-height: calc(100vh - 62px);

    @media screen and (max-width: 900px) {
      overflow-y: unset;
      max-height: unset;
    }
  }

  .Exchange-right {
    overflow-y: auto;
    border-left: rgba(255, 255, 255, 0.1) solid 1px;
    max-height: calc(100vh - 62px);

    @media screen and (max-width: 700px) {
      border: none;
      // margin-top: 16px;
      overflow-y: unset;
      max-height: unset;
    }
  }

  .ExchangeChart-top {
    .info-main-swap {
      @media screen and (max-width: 1350px) {
        top: 17px !important;
        right: 24px !important;
      }

      @media screen and (max-width: 700px) {
        top: 2px !important;
        right: 8px !important;
      }
    }
  }

  .Exchange-list-tabs {
    width: fit-content;
    border-radius: 4px;
    border: none;
    background: transparent;
    border-radius: 4px;

    .Tab-option {
      border-radius: 4px;
      padding: 12px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      color: var(--white-60, var(--text-text-secondary, #848e9c));
      font-size: 12px !important;
    }

    .active {
      // background: var(--white-10, rgba(255, 255, 255, 0.1)) !important;
      color: var(--white-100, #fff) !important;
    }
  }
}

.Exchange-swap-button-container {
  button {
    height: 40px !important;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}

.swap-box-swap-container {
  .swap-box-v2 {
    padding-bottom: 14px;
  }
}

.Collateral-Modal {
  .Modal-title-bar {
    margin: 0;
  }
}

// .App-slider .rc-slider-track {
//   background: #c1ff99;
// }

// .App-slider .rc-slider-dot {
//   border: none;
//   border-radius: 1px;
//   width: 2px;
//   margin-left: -1px;
//   background: #101233;
// }

// .App-slider .rc-slider-dot-active {
//   background: #c1ff99;
// }

.App-slider .rc-slider-handle {
  background-color: #191b2e;
  border: 2px solid #c1ff99;
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  height: 18px;
  margin-top: -5px;
  position: absolute;
  touch-action: pan-x;
  width: 18px;
}

// .App-slider .rc-slider-handle:active {
//   box-shadow: 0 0 0.4rem 0.6rem #ffcd572c;
// }

// .App-slider .rc-slider-mark-text,
// .App-slider .rc-slider-mark-text-active {
//   color: white;
//   opacity: 0.5;
// }

// .App-slider .rc-slider-mark-text:hover,
// .App-slider .rc-slider-mark-text-active:hover {
//   opacity: 1;
// }

.rc-slider-tooltip {
  z-index: 5;
}

.rc-slider-tooltip-inner {
  box-shadow: none;
  padding: 0.465rem 0.8rem;
  background: #303652;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #303652;
}

.normalSlider {
  .rc-slider-rail {
    background: rgba(255, 255, 255, 0.1) !important;
    height: 4px !important;
  }

  .rc-slider-step {
    .rc-slider-dot {
      background: rgba(255, 255, 255, 0.1);
      top: 0px !important;
      width: 2px !important;
      height: 12px !important;
      top: -2px !important;

      &:first-child {
        left: 0px !important;
      }
    }

    .rc-slider-dot-active {
      background: #fff !important;
      border: none !important;
      width: 12px !important;
      height: 12px !important;
    }
  }

  .rc-slider-mark {
    top: 22px !important;
  }

  .rc-slider-mark-text-active {
    opacity: 1 !important;
  }

  .rc-slider-handle {
    top: 5px !important;
  }

  .rc-slider-track {
    height: 4px !important;
  }

  .rc-slider-mark-text {
    opacity: 0.6 !important;
  }
}

.scan-hide-tag {
  top: -2px !important;
}

.Exchange-left {
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.close-container {
  .menu-items {
    padding: 0px;
  }
}

.swap-v1-container {
  padding-top: 0 !important;

  @media screen and (max-width: 1350px) {
    .ExchangeChart-additional-info {
      width: 100% !important;
      justify-content: flex-end;
      display: flex;
      flex-direction: row;
      align-items: center;

      .ExchangeChart-info-label {
        width: unset !important;
      }
    }
  }
}

.Exchange-swap-section-bottom {
  .PositionEditor-token-symbol {
    font-weight: 500;
  }
}

.select-symbol-container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .supported-version {
    display: flex;
    gap: 4px;
    align-items: center;

    .title {
      color: var(--white-60, #848e9c);
      font-family: "DM Sans";
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      /* 12px */
    }

    .ver {
      display: flex;
      padding: 2px var(--Border_radius, 4px);

      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: var(--Border_radius, 4px);
      background: var(--white-10, rgba(255, 255, 255, 0.1));
      color: var(--white-60, #848e9c);
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
  }
}

.trade-version {
  @media screen and (max-width: 1350px) {
    position: absolute !important;
    width: fit-content !important;
    right: 16px;
    top: 11px;
    height: fit-content !important;
  }
}

.max-leverage-container {
  @media screen and (max-width: 1350px) {
    padding: 8px !important;
    padding-left: 0 !important;
  }
}

.trade-version {
  .version-tab {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100% !important;
  }
}

.ExchangeChart-top {
  @media screen and (max-width: 1350px) {
    .trade-version {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      border: none;
    }

    .info-main-price {
      top: 15px !important;
      right: unset !important;
      left: 200px;
    }
  }

  @media screen and (max-width: 767px) {
    .info-main-price {
      top: 14px !important;
      right: unset !important;
      left: 177px;
    }

    .trade-version {
      right: 8px !important;
      top: -1px !important;
    }
  }
}

.ExchangeChart-top {
  height: 76px !important;

  @media screen and (max-width: 1350px) {
    height: unset !important;
  }
}

.apr-token-group {
  display: flex;
  gap: 4px;
}

.Exchange {
  .Modal-body {
    .Tooltip-popup {
      max-width: 320px;
    }
  }
}

.swap-v1-container {
  .Exchange-swap-box-info {
    gap: 0;

    .Exchange-info-row {
      height: 20px;

      .Tooltip-handle {
        color: var(--text-secondary);
      }
    }
  }
}

.Exchange-swap-box-inner,
.item-section-container {
  .small-text-number {
    font-size: 14px;
    font-weight: bold;
    margin-right: 4px;
  }
}

.spot-arregator-container {
  .price-value {
    .small-text-number {
      font-size: 16px;
      font-weight: bold;
      // margin-right: 4px;
    }
  }
}

.positionHistory-modal {
  .Modal-content {
    overflow-y: auto !important;
  }
}

.spot-aggregator-wrapper {
  @media screen and (max-width: 1350px) {
    .Exchange-updated-ui .Exchange-content .ExchangeChart {
      height: 49.6rem !important;
      border-radius: 0px !important;
    }

    .ExchangeChart.tv .ExchangeChart-bottom {
      top: 80px !important;
      left: 24px;
      right: 24px;
      bottom: 24px;
    }
  }

  @media screen and (max-width: 700px) {
    .Exchange-updated-ui .Exchange-content .ExchangeChart {
      height: 30rem !important;
    }

    .ExchangeChart.tv .ExchangeChart-bottom {
      top: 96px !important;
      left: 16px;
      right: 16px;
      bottom: 16px;
    }
  }

  .ExchangeChart-bottom-controls {
    position: relative;
    z-index: 3;
  }
}

.label-box-container {
  display: flex;
  align-items: center;

  .label-box-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;

    text-decoration: none;

    display: flex;
    width: 108px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    height: 100%;
  }

  .box-divider {
    width: 1px;
    height: 24px;
    background: var(--Border, rgba(255, 255, 255, 0.1));
  }
}

.theme--light {
  .label-box-container {
    .label-box-title {
      color: rgba(13, 26, 22, 0.7) !important;
    }

    .label-box-title--active {
      color: #02b27f !important;
    }

    .box-divider {
      background: rgba(13, 26, 22, 0.07);
    }
  }
}

@media screen and (max-width: 700px) {
  .label-box-title {
    padding: 12px 10px !important;
    width: 100px !important;
  }

  .swap-box-swap-container {
    .Exchange-swap-box-inner {
      padding: 16px !important;
    }
  }
}

@media screen and (max-width: 1110px) {
  .ExchangeChart-top .ExchangeChart-top-inner {
    flex-direction: column;
  }
}

.exchange-chart-info-container {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  // flex: 1;
  // overflow-x: auto;
  // overflow-y: hidden;
  padding: 6px 0px;
  height: 100%;
  justify-content: space-between;

  @media screen and (max-width: 1461px) {
    gap: 24px;
  }

  @media screen and (max-width: 1400px) {
    gap: 16px;
  }
}

.exchange-v2 {
  @media screen and (max-width: 700px) {
    .ExchangeChart {
      padding-top: 0px !important;
    }
  }

  @media screen and (max-width: 1110px) {
    .ExchangeChart-top {
      padding: 0px 8px !important;
    }

    .ExchangeChart-top-inner {
      padding-left: 0;
    }

    .ExchangeChart-title-container {
      margin-right: 0 !important;
    }
  }

  @media screen and (max-width: 900px) {
    .info-main-price {
      top: 50px !important;
    }
  }

  @media screen and (max-width: 767px) {
    .exchange-chart-info-container {
      margin-top: 0px !important;
    }

    .info-main-price {
      left: 16px;
      top: 49px;
    }
  }
}

.exchange-v2 {
  @media screen and (max-width: 1620px) {
    .ExchangeChart-top {
      display: flex !important;
      width: 100%;

      &::-webkit-scrollbar {
        width: unset !important;
        height: 6px !important;
      }

      .ExchangeChart-top-inner {
        flex: 1;
        width: 100% !important;
      }
    }

    .trade-version {
      width: 105px;
    }

    .exchange-chart-info-container {
      overflow-x: unset;
      width: unset !important;
    }
  }
}

.exchange-v1 {
  .PositionsList {
    .Tab {
      .Tab-option {
        color: #807e7c;
        padding-bottom: 10px;
        padding-top: 10px;
      }

      .active {
        color: white;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .Exchange-left {
      padding: 0px 16px !important;

      .ExchangeChart {
        padding-top: 16px;
      }
    }
  }
}

.Exchange-lists {
  &.large {
    .item-exchange {
      margin-top: -12px;
    }
  }

  // @media screen and (max-width: 991px) {
  //   background: none !important;
  // }
  @media screen and (max-width: 700px) {
    margin: 16px;
  }
}

.exchange-v2 {
  .Exchange-content {
    @media screen and (max-width: 700px) {
      padding: 16px !important;
    }
  }
}

.exchange-v1 {
  .Exchange-content {
    @media screen and (max-width: 900px) {
      padding: 16px !important;
    }

    @media screen and (max-width: 700px) {
      padding: 0px !important;
    }
  }

  .Exchange-left {
    // @media screen and (max-width: 900px) {
    //   margin-bottom: 16px;
    // }
  }
}

.Exchange-right {
  @media screen and (max-width: 700px) {
    margin-left: 0px !important;
  }
}

.table-content-invalid {
  td {
    padding-bottom: 40px !important;
  }

  .close-container-td {
    width: 202px !important;
  }

  .order-info {
    opacity: 0.5;
  }

  position: relative !important;

  .error-order {
    position: absolute !important;
    left: 12px;
    top: 55px;
  }
}

.position-receive-modal {
  .TokenSelector-tokens {
    padding: 24px !important;
  }
}

.execute-tab-container {
  display: flex;
  align-items: center;
  gap: 24px;

  .group-actions {
    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: #fff;
    }
  }

  @media screen and (max-width: 700px) {
    gap: 16px;
  }
}

.reduce-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 700px) {
    gap: 8px;

    span {
      font-size: 10px;
    }
  }
}

.orderly-chart-container {
  display: flex;

  .exchange-v2 {
    .ExchangeChart-bottom-content {
      flex: 1;
      position: relative !important;
    }
  }
}

.update-levrage-button-container {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  gap: 24px;

  button {
    flex: 1;
  }
}

.PositionsList {
  padding-bottom: 24px;
}

.unsettle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;

  .unsettle-label {
    color: #848e9c;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .settle-btn {
    color: #0ecb81;
    cursor: pointer;

    &:hover {
      color: #3de4a4;
    }
  }
}

.Exchange-swap-option-tabs {
  .Tab-option {
    span {
      text-transform: uppercase !important;
    }
  }
}

.exchange-chart-info-container {
  @media screen and (min-width: 900px) {
    .info-main-price {
      width: 100px;
    }

    // .ExchangeChart-additional-info {
    //   width: 80px;
    // }

    .rate {
      width: unset;
      min-width: 80px;
    }

    .funding {
      width: 106px;
    }
  }

  @media screen and (max-width: 1460px) {
    gap: 16px !important;
  }

  @media screen and (max-width: 1380px) {
    gap: 10px !important;
  }
}

.max-account-levrage-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;

  button {
    width: 56px;
    height: 20px;
  }
}

.profit-loss-label {
  img {
    position: relative;
    bottom: 1.5px;
  }
}

.Exchange-leverage-stat {
  @media screen and (max-width: 1350px) {
    .leverage-pen {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        position: unset !important;
        border: none !important;
      }
    }
  }
}

.max-account-levrage-container {
  .leverage-pen {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      position: unset !important;
      border: none !important;
    }
  }
}

.risk-orderly {
  div {
    justify-content: flex-end !important;
  }
}

.summary-container {
  margin-top: 16px;
  padding: 12px;
  padding-bottom: 0px;

  div,
  span {
    font-size: 12px !important;
  }

  .title {
    margin-bottom: 8px;
  }

  @media screen and (max-width: 700px) {
    padding: 0 !important;
    padding-top: 16px !important;
    margin-top: 0px;
    border-top: 1px solid #2B3139;
  }
}

.cancel-order-txt {
  background: #262933;
  color: #848e9c !important;
  padding: 0px 4px;
  font-size: 12px;
  // margin-left: 4px;
  border-radius: 2px;
  font-weight: 500 !important;
}

.Exchange-swap-box {
  padding: 12px !important;
}

.perp-type {
  height: 48px;

  @media screen and (max-width: 768px) {
    height: 45px;
  }

  border-bottom: 1px dashed var(--Border, #2B3139);

  .perp-type-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px dashed #2B3139;
  }

  .left,
  .right {
    padding: 10px 20px;
    font-weight: bold;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    border-radius: 0px;
    justify-content: center;
  }

  .left {
    background-color: #1d1f26;
    margin-right: 10px;
    flex: 1;
    font-size: 14px;
  }

  .right {
    background-color: #1d1f26;
    border: none;
    cursor: pointer;
    flex: 1;
    font-size: 14px;
  }

  .dropdown-icon {
    margin-left: 4px;
    font-size: 12px;
  }

  .left:hover,
  .right:hover {
    background-color: #45474a !important;
    /* Màu nền khi hover */
  }
}

.swap-box-v2 {
  padding-top: 16px !important;
  border: none !important;

  .tab-exchange-container {
    .ant-select-arrow {
      img {
        filter: brightness(0) saturate(100%) invert(52%) sepia(0%) saturate(6377%) hue-rotate(319deg) brightness(95%)
          contrast(92%);
      }
    }

    display: flex;
    gap: 8px;
    align-items: center;

    .tab-content {
      flex: 1;
    }

    .tab-swap-option {
      height: 36px;
      padding: 10px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: var(--Border-Radius-Medium, 4px);
      background: var(--Nature-2, #1d1f26);
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      cursor: pointer;
      color: #848e9c;

      &:hover {
        background: rgba(14, 203, 129, 0.05) !important;
        color: #0ecb81 !important;
      }
    }
    .tab-swap-option-short {
      &:hover {
        background: rgba(228, 62, 83, 0.1) !important;
        color: #e43e53 !important;
      }
    }
    .tab-long-active {
      border: 1px solid var(--Functional-Green, #0ecb81) !important;
      background: rgba(14, 203, 129, 0.05) !important;
      color: #0ecb81 !important;
    }

    .tab-option-long {
      .ant-select-selection-item {
        color: #0ecb81 !important;
      }
    }

    .tab-option-short {
      .ant-select-selection-item {
        color: #e43e53 !important;
      }
    }

    .tab-short-active {
      border: 1px solid var(--Functional-Green, #e43e53) !important;
      background: rgba(228, 62, 83, 0.1) !important;
      color: #e43e53 !important;
    }

    .tab-option {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--Border-Radius-Medium, 4px);
      border: 1px solid var(--Border, #2B3139);
      height: 36px;
    }
  }

  .ant-dropdown-trigger:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .transparent-ant-select-selection-item {
    .ant-select-selector {
      .ant-select-selection-item {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
    }
  }
}

.Exchange-swap-section {
  padding: 10px 8px !important;

  .Exchange-swap-section-bottom {
    display: flex !important;
    flex-direction: row !important;
    gap: 0px !important;
    align-items: center !important;
    width: 100% !important;
    padding-bottom: 0 !important;

    .input-label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #848e9c !important;
      margin-bottom: 0 !important;
    }

    input {
      text-align: right;
    }

    .PositionEditor-token-symbol {
      position: relative;
      top: 1px;
      margin-left: 8px;
    }
  }

  .Exchange-swap-input-container {
    flex: 1;
    margin-left: 8px;

    input {
      padding-right: 0 !important;

      &::placeholder {
        color: #848e9c !important;
      }
    }
  }
}

.Exchange-right {
  max-height: unset !important;
  height: fit-content !important;
  overflow-y: auto !important;
  min-height: calc(100vh - 62px) !important;
  max-height: calc(100vh - 62px) !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
  background: #181a20 !important;

  // @media screen and (max-width: 1350px) {
  //   min-height: calc(100vh - 106px) !important;
  //   max-height: calc(100vh - 106px) !important;
  // }

  @media screen and (max-width: 700px) {
    min-height: unset !important;
    max-height: unset !important;

    .Exchange-swap-box {
      display: flex !important;
      flex-direction: row !important;

      .orderbook-container-mobile {
        width: 120px !important;
        padding-top: 0px;
        padding-bottom: 0;
        height: fit-content !important;
        border: none !important;
        // border-right: 1px solid #2B3139 !important;

        .header {
          justify-content: center;
          border: 1px solid #2B3139 !important;
          border-radius: 4px;
          margin-left: 4px;
          margin-right: 4px;
        }

        .order-header {
          padding-left: 4px;
          padding-right: 4px;
          margin-top: 4;

          .order-value {
            font-size: 10px !important;

            span {
              display: flex;
              flex-direction: column;
              gap: 2px;
            }
          }
        }

        .orderbook-body {
          min-height: 460px;
        }

        .order-row {
          padding: 0 4px;
          width: 100% !important;

          .price,
          .total {
            min-width: unset !important;
            font-size: 10px !important;
          }
        }

        .current-price {
          flex-direction: column;

          .last-price,
          .mark-price {
            font-size: 12px !important;
          }
        }
      }

      .sapbox-orderly {
        flex: 1;
        padding-left: 8px;
        border-left: 1px solid #2B3139 !important;

        .percentahe-value {
          width: 50px;
          padding: 4px;
          gap: 2px;

          input {
            font-size: 12px !important;
            width: 30px;
          }

          span {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

.last-price {
  .custom-antd-tooltip {
    font-size: 16px !important;
  }
}

.Collateral-modal-deposit {
  .Exchange-swap-section-bottom {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }

  .Exchange-swap-input-container {
    margin-top: 0 !important;
    height: 25px;
    display: flex;
    align-items: center;

    input {
      text-align: left !important;
    }

    .Exchange-swap-max {
      right: 0 !important;
      top: 0 !important;
    }
  }
}

.long-tag {
  position: absolute;
  top: 16px;
  left: 10px;
  height: 30px;
  width: 4px;
  border-radius: 2px;
  background: #0ecb81;
}

.short-tag {
  position: absolute;
  top: 16px;
  left: 10px;
  height: 30px;
  width: 4px;
  border-radius: 2px;
  background: #e43e53;
}

.ExchangeChart-title-container {
  @media screen and (max-width: 700px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

#tradingview_68186 {
  @media screen and (max-width: 1023px) {
    height: 350px !important;
  }

  @media screen and (max-width: 700px) {
    height: 320px !important;
  }
}

.info-mobile {
  @media screen and (max-width: 700px) {
    padding: 0 !important;
  }
}

.max-leverage-container {
  @media screen and (max-width: 700px) {
    padding-left: 0 !important;
  }
}

.TVChartContainer {
  @media screen and (max-width: 1024px) {
    iframe {
      height: 385px !important;
    }
  }

  @media screen and (max-width: 700px) {
    iframe {
      height: 320px !important;
    }
  }
}

.select-symbol-container {
  .token-maxleverage {
    background: #383b46;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 4px;
    border-radius: 4px;
  }

  .volume-24h {
    font-size: 14px;
    font-weight: 500;
  }
}
