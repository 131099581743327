.ant-pagination-mini {
  display: flex;
  justify-content: flex-end;
  font-family: "Roboto";
  margin-top: 24px;
  color: #fff;
  gap: 8px;

  @media screen and (max-width: 400px) {
    gap: 4px;
  }

  > li {
    height: 40px !important;
  }

  * {
    color: #fff;
  }

  .ant-pagination-item-active {
    background-color: #1eb2fc;

    a {
      color: #fff !important;
    }
  }

  .ant-pagination-item-active:hover {
    border-color: transparent;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    width: 40px !important;
    height: 40px;
    display: flex;
    border: 1px solid #ffffff1a;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    line-height: unset;
  }

  .ant-pagination-item-ellipsis {
    color: #fff !important;
  }
}

.pagination-v2 {
  display: flex;
  justify-content: flex-end;

  margin-top: 16px;
  color: #fff;

  > li {
    height: 32px !important;
  }

  * {
    color: #fff;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    border-radius: var(--Border-Radius-Medium, 4px);
    border: 1px solid var(--Border, #2B3139);
    &:hover {
      border: 1px solid #1eb2fc;
    }
  }

  .ant-pagination-item {
    width: 32px;
    height: 32px;
    border-radius: var(--Border-Radius-Medium, 4px);
    border: 1px solid var(--Border, #2B3139);

    a {
      width: 32px;
      height: 32px;
      color: #848e9c !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      font-family: "DM Sans";
    }

    &:hover {
      border: 1px solid #1eb2fc;
    }
  }

  .ant-pagination-item-active {
    background: transparent !important;

    a {
      color: #1eb2fc !important;
    }
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    width: 32px !important;
    height: 32px;
    display: flex;
    border: 1px solid var(--Border, #2B3139);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    line-height: unset;
    padding-bottom: 0;
  }

  .ant-pagination-item-ellipsis {
    color: #848e9c !important;
  }

  .ant-pagination-prev {
    padding-bottom: 0 !important;
  }
  .ant-pagination-next {
    padding-bottom: 0 !important;
  }
}
