.theme--light {
  .Exchange {
    background: white;
  }

  .trade-version {
    border-color: var(--Border, rgba(13, 26, 22, 0.07)) !important;
  }

  .Exchange-right {
    background: white !important;
  }

  .exchange-v2 {
    .Exchange-right,
    .Modal-content {
      background: white !important;
    }
  }
  .exchange-v2 {
    .Exchange-right {
      border-left: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
    }
  }

  .box-trade-long {
    background: white !important;
  }

  .Exchange-swap-order-type-tabs {
    .Tab-option {
      color: rgba(13, 26, 22, 0.7) !important;
    }
  }

  .exchange-v2 .Exchange-updated-ui .Exchange-content .Exchange-swap-order-type-tabs {
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
  }

  .exchange-v2 .Exchange-updated-ui .Exchange-content .Exchange-swap-order-type-tabs .Tab-option {
    color: #0d1a16 !important;

    &:hover {
      background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
    }
  }

  .exchange-v2 .Exchange-updated-ui .Exchange-content .Exchange-swap-order-type-tabs .active {
    background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
    color: #02b27f !important;
  }

  .Exchange-swap-section {
    background: var(--Background-Input_Background, rgba(38, 77, 65, 0.07)) !important;

    .Exchange-swap-section-top {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

      .Tooltip-handle {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

        span {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }

    .Exchange-swap-input-container {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

      .usd-txt {
        color: #0d1a16 !important;
      }

      input {
        color: #0d1a16 !important;

        &::placeholder {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }
  }

  .percentage-btn-container {
    .percentahe-value {
      border: 1px solid var(--Primary, #02b27f) !important;

      input {
        color: var(--Primary, #02b27f) !important;
      }

      &.init-value {
        border-color: #0d1a16 !important;

        & > input,
        & > span {
          color: #0d1a16 !important;
        }
      }

      span {
        color: var(--Primary, #02b27f) !important;
      }
    }

    .percentage-btn {
      cursor: pointer;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

      &:hover {
        background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
      }
    }

    .percentage-btn-selected {
      border: 1px solid var(--Primary, #02b27f) !important;
      color: #02b27f !important;
    }
  }
  .trade-version {
    border-left: 1px solid rgba(13, 26, 22, 0.07) !important;
  }
  .Exchange-info-row {
    .Exchange-info-label {
      color: rgba(13, 26, 22, 0.7) !important;
      opacity: 1 !important;

      div {
        color: rgba(13, 26, 22, 0.7) !important;
      }

      span {
        color: rgba(13, 26, 22, 0.7) !important;
      }
    }
  }

  .Exchange-leverage-slider-container {
    .percentahe-value {
      border: 1px solid var(--Primary, #02b27f) !important;

      input {
        color: var(--Primary, #02b27f) !important;
      }

      span {
        color: var(--Primary, #02b27f) !important;
      }
    }
  }

  .rc-slider {
    .rc-slider-rail {
      background: var(--Border, rgba(13, 26, 22, 0.07)) !important;
    }

    .rc-slider-track {
      background: var(--Primary, #02b27f) !important;
    }

    .rc-slider-dot-active {
      background: var(--Primary, #02b27f) !important;
    }

    .rc-slider-handle {
      background: #fff;
      border: 2px solid #02b27f;
    }
  }

  .shortSlider {
    .rc-slider-rail {
      background: var(--Border, rgba(13, 26, 22, 0.07)) !important;
    }

    .rc-slider-track {
      background: var(--Primary, #d62f44) !important;
    }

    .rc-slider-dot-active {
      background: var(--Primary, #d62f44) !important;
    }

    .rc-slider-handle {
      background: #fff;
      border: 2px solid #d62f44;
    }

    .rc-slider-mark-text {
      color: rgba(13, 26, 22, 0.7) !important;
    }

    .rc-slider-mark-text-active {
      color: #0d1a16 !important;
    }
  }

  .toogle-card-container {
    .title-container {
      .title {
        color: #0d1a16;
      }

      button {
        background: #0d1a1612 !important;
      }

      .ant-switch-checked {
        background: #02b27f !important;
      }
    }
  }

  .Exchange-swap-section:has(input:focus) {
    outline: 1px solid #1EB2FC;
  }

  .negative {
    color: #d62f44 !important;

    span,
    div {
      color: #d62f44 !important;
    }
  }

  .positive {
    color: #02b27f !important;

    span,
    div {
      color: #02b27f !important;
    }
  }

  .trade-v1 {
    .position-share {
      li {
        color: white !important;
      }

      .prices {
        color: white !important;

        p {
          color: white !important;
        }

        .price {
          color: #ffdf76 !important;
        }
      }

      .referral-code-info {
        color: white !important;

        .label {
          color: rgba(255, 255, 255, 0.6) !important;
        }

        .code {
          color: white !important;
        }
      }

      .positive {
        color: #05eca8 !important;

        span {
          color: #05eca8 !important;
        }

        div {
          color: #05eca8 !important;
        }
      }

      .negative {
        color: #E43E53!important;

        span {
          color: #E43E53!important;
        }

        div {
          color: #E43E53!important;
        }
      }
    }
  }

  .normalSlider {
    span.rc-slider-mark-text {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

      &.rc-slider-mark-text-active {
        color: var(--Text-Text_Primary, #0d1a16) !important;
      }
    }
  }

  .Exchange-list-tab-container {
    .Tab {
      .Tab-option {
        color: rgba(13, 26, 22, 0.7) !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        &:hover {
          background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
        }
      }

      .active {
        background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
        color: #02b27f !important;
      }
    }
  }

  .Exchange-list-tabs {
    .active {
      background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
      color: #02b27f !important;
    }
  }

  .exchange-v2 .Exchange-updated-ui .Exchange-lists .Exchange-list-tabs .active {
    background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
    color: #02b27f !important;
  }

  .ExchangeChart-top-inner {
    .chart-token-selector {
      background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;

      .chart-token-selector--current {
        color: #0d1a16 !important;
      }

      .arrow-chart {
        filter: brightness(0) !important;
      }

      &:hover {
        background: var(--HighLight, rgba(26, 175, 133, 0.15)) !important;
      }
    }
  }

  .trade-version {
    .trade-version-container {
      .version-tab-active {
        border: 1px solid var(--Primary, #02b27f) !important;
        color: var(--Primary, #02b27f) !important;
      }
    }
  }

  .Exchange-lists table .table-content {
    background: white;
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

    .PositionDropdown-dots-icon {
      // img {
      //   filter: brightness(0) !important;
      // }
    }
  }

  .pagination-v2 {
    .ant-pagination-item {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

      a {
        color: var(--Text-Text_Primary, #0d1a16) !important;
      }

      &:hover {
        border: 1px solid var(--Primary, #02b27f) !important;
        background: none !important;

        a {
          color: var(--Primary, #02b27f) !important;
        }
      }
    }

    .ant-pagination-item-active {
      border: 1px solid var(--Border_Highlight, rgba(255, 255, 255, 0.1)) !important;
      background: var(--Primary, #02b27f) !important;

      a {
        color: white !important;
      }

      &:hover {
        border: 1px solid var(--Border_Highlight, rgba(255, 255, 255, 0.1)) !important;
        background: var(--Primary, #02b27f) !important;

        a {
          color: white !important;
        }
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border-radius: var(--Base-Unit-XS-8, 8px);
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

      &:hover {
        border: 1px solid var(--Primary, #02b27f);
      }

      svg {
        filter: brightness(0);
        width: 16px;
        height: 16px;

        // path {
        //   fill: #0D1A16;
        // }
      }
    }

    .ant-pagination-jump-next {
      padding: 0 !important;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .ExchangeChart-top-inner {
    .chart-token-menu-items {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
      background: var(--Background-Modal_Background, #fff) !important;
      box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;

      .select-market-container {
        background: var(--Background-Modal_Background, #fff) !important;
        box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;

        .top {
          .filter {
            button {
              border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
              color: var(--Text-Text_Primary, #0d1a16) !important;
            }

            .active {
              background: var(--Border, rgba(13, 26, 22, 0.07)) !important;
            }
          }
        }

        .list-tokens-chart {
          .row {
            &:hover {
              background: var(--Border, rgba(13, 26, 22, 0.07)) !important;
            }

            .price {
              color: #0d1a16 !important;
            }
          }
        }

        .input-section {
          background: var(--Background-Input_Background, rgba(38, 77, 65, 0.07)) !important;
          input {
            color: #0d1a16 !important;
          }
          img {
            filter: brightness(0);
          }
        }

        .select-symbol-container {
          .symbol {
            color: #0d1a16;
          }

          .supported-version {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));

            .title {
              color: rgba(13, 26, 22, 0.7) !important;
            }

            .ver {
              color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .positive .Tooltip-handle {
    color: #02b27f !important;
  }

  .close-container {
    .Exchange-list-action {
      color: #02b27f !important;

      &:hover {
        color: #03805b !important;
      }
    }

    .menu-items {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
      background: var(--Background-Tooltip_Background, #fff) !important;

      .menu-item {
        img {
          filter: brightness(0);
        }
      }
    }
  }

  .square-container {
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
  }

  .side {
    background: rgba(13, 26, 22, 0.07) !important;
    color: #0d1a16 !important;
  }

  .exchange-v2 .order-title-line-details .side-long {
    background: var(--Chips-Long_Background, rgba(2, 178, 127, 0.1)) !important;
    color: #02b27f !important;
  }

  .PositionDropdown-extra-options .menu-items .menu-item:hover {
    background: var(--Border, rgba(13, 26, 22, 0.07)) !important;
  }

  @media screen and (max-width: 1300px) {
    .PositionDropdown-extra-options {
      .menu-items {
        background: #fff !important;
        box-shadow: 4px 0px 24px 0px rgba(0, 0, 0, 0.2);

        img {
          filter: brightness(0);
        }
      }
    }
  }

  .side-short {
    background: rgba(246, 71, 93, 0.1) !important;
    color: #d62f44 !important;
  }

  .position-info-container {
    .position-info {
      .title {
        color: #0d1a16;
      }

      .side {
        background: rgba(13, 26, 22, 0.07) !important;
        color: #0d1a16 !important;
      }

      .side-long {
        background: var(--Chips-Long_Background, rgba(2, 178, 127, 0.1)) !important;
        color: #02b27f !important;
      }

      .side-short {
        background: rgba(246, 71, 93, 0.1) !important;
        color: #d62f44 !important;
      }
    }

    .position-id {
      color: rgba(13, 26, 22, 0.7) !important;
    }
  }

  .postion-history {
    color: #02b27f !important;
  }

  .divider {
    background: rgba(13, 26, 22, 0.07) !important;
  }

  .PositionDropdown-dots-icon {
    &:hover {
      background: #c1ff991a !important;
    }
  }

  .Modal-content {
    .content-container {
      border-top: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

      .Exchange-info-row {
        .align-right {
          font-weight: 700;
        }
      }
    }
  }

  .green-value {
    color: #02b27f !important;
  }

  .position-list-leve {
    background: #fef6d8 !important;
    font-weight: 700 !important;
    color: #d69e03 !important;
  }

  .chart-positions {
    .Checkbox-icon-wrapper {
      path {
        color: #0d1a16b2 !important;
      }
    }

    &:hover,
    &.active {
      .Checkbox-icon-wrapper {
        path {
          color: #02b27f !important;
        }
      }
    }
  }

  .Exchange-should-show-position-lines {
    .group-actions {
      font-size: 14px;
    }
  }

  .position-change-line {
    .position-change-info {
      color: #0d1a16 !important;

      &:first-child {
        color: rgba(13, 26, 22, 0.7) !important;
      }
    }
  }

  .rc-slider-mark-text {
    color: rgba(13, 26, 22, 0.7) !important;
  }

  .rc-slider-mark-text-active {
    color: #0d1a16 !important;
  }

  .box-trade-short {
    background: white !important;
  }

  .exchange-v2 .risk.low {
    span {
      color: #02b27f !important;
    }
  }

  .exchange-v2 .risk.medium {
    span {
      color: #d69003 !important;
    }
  }

  .exchange-v2 .risk.high {
    span {
      color: #d62f44 !important;
    }
  }

  .no-data-record,
  .no-position-card,
  .no-data-card {
    background: white !important;
  }

  .Exchange-left {
    background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%) !important;
  }

  .exchange-chart-info-container {
    .orr {
      color: #0d1a16b2 !important;
    }

    .ExchangeChart-info-label span {
      color: #0d1a16b2 !important;
    }
  }

  .ExchangeChart-top {
    border-bottom: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
  }

  .ExchangeChart-bottom {
    border-bottom: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
  }

  .exchange-v1 .Exchange-swap-box .Exchange-swap-order-type-tabs {
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

    .Tab-option {
      color: #0d1a16 !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      &:hover {
        background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
      }
    }

    .active {
      background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
      color: #02b27f !important;
    }
  }

  .swap-box-v2 {
    border-bottom: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
  }

  .Exchange-swap-option-tabs {
    .short {
      color: #d62f44 !important;
    }

    .long {
      color: var(--Functional-Green, #05b681) !important;
    }
  }

  .Exchange-swap-max {
    outline: none !important;
    background: #02b27f !important;
    border-radius: var(--Base-Unit-XS-8, 8px) !important;

    span {
      color: white !important;
      font-weight: 500;
      font-size: 14px;
    }

    &:hover {
      background: #02845e !important;
    }
  }

  .no-position .no-position-card {
    background: white !important;
  }

  .TradeHistory {
    .new-history {
      .tradeHistory-Header {
        background: none !important;

        th {
          color: rgba(13, 26, 22, 0.7) !important;
        }
      }

      tr {
        background: white !important;

        td {
          background: white !important;
        }
      }
    }
  }

  .trade-version-container {
    .version-tab {
      &:hover {
        background: var(--Background-Input_Background, rgba(38, 77, 65, 0.07)) !important;
      }
    }
  }

  .timestamp-cell {
    color: #0d1a16 !important;
  }

  .Exchange-swap-ball {
    background: #02b27f !important;

    path {
      color: white !important;
    }

    &:hover {
      background: #03805b !important;
    }
  }

  .trade-v1 {
    .Exchange-swap-market-box {
      .App-card-divider {
        background: none;
      }
    }

    .Exchange-content {
      @media screen and (max-width: 1350px) {
        background: #eff2f7 !important;
      }
    }
  }

  .trade-v1 {
    .leverage-settings-short {
      .Checkbox-icon {
        path {
          color: rgba(13, 26, 22, 0.1) !important;
        }
      }

      .active {
        color: #d62f44 !important;

        path {
          color: #d62f44 !important;
        }
      }
    }
  }

  .Exchange-swap-ball-red {
    background: #d62f44 !important;

    path {
      color: white !important;
    }

    &:hover {
      background: #bf3649 !important;
    }
  }

  .Exchange-swap-max {
    &:hover {
      background: #02845e !important;
    }
  }

  .new-history-cell {
    .long-tag {
      background: var(--Chips-Long_Background, rgba(2, 178, 127, 0.1)) !important;
      color: var(--Chips-Long_Text, #02b27f) !important;
    }

    .short-tag {
      background: var(--Chips-Short_Background, rgba(246, 71, 93, 0.1)) !important;
      color: var(--Chips-Short_Text, #d62f44) !important;
    }
  }

  .exchange-token-value {
    .symbol-styled {
      color: rgba(13, 26, 22, 0.7) !important;
    }
  }

  .symbol-styled {
    color: rgba(13, 26, 22, 0.7) !important;
  }

  .box-trade-swap {
    .TokenSelector {
      .TokenSelector-box {
        font-weight: 700;
      }
    }
  }

  .swap-v2-wrapper {
    .swap-token-selected-wrapper {
      .symbol-value {
        color: rgba(13, 26, 22, 0.7) !important;
      }
    }
  }

  .Modal-content {
    .content-container {
      .Tab {
        .Tab-option {
          color: rgba(13, 26, 22, 0.7) !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;

          &:hover {
            background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
          }
        }

        .active {
          background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
          color: #02b27f !important;
        }
      }
    }
  }

  .ver {
    background: var(--Border, rgba(13, 26, 22, 0.07)) !important;
  }

  .Exchange-list {
    .App-card {
      background: white !important;
    }
  }

  @media (max-width: 1350px) {
    .Exchange-lists.small {
      background: #eff2f7 !important;
    }
  }

  button.PositionDropdown-dots-icon {
    color: #0d1a16 !important;

    img {
      filter: brightness(0);
    }

    &:hover {
      background: transparent !important;

      & img {
        filter: invert(53%) sepia(58%) saturate(4106%) hue-rotate(132deg) brightness(95%) contrast(98%) !important;
      }

      @media screen and (max-width: 1300px) {
        & img {
          filter: brightness(0) !important;
        }
      }
    }

    &:active {
      color: #0d1a16 !important;
    }

    @media screen and (max-width: 1300px) {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

      &:hover:enabled,
      &:active {
        color: #0d1a16 !important;
        border: 1px solid #02b27f !important;
      }
    }
  }

  .TradeHistory {
    .table-mobile {
      background: white !important;

      .header {
        border: none !important;

        .market {
          a {
            img {
              filter: brightness(0);
            }
          }

          .coin {
            color: #0d1a16;
          }
        }
      }

      .close-on {
        border-top: 1px solid rgba(13, 26, 22, 0.07);

        .label {
          color: #0d1a16 !important;
        }
      }
    }
  }

  .Exchange-updated-ui {
    @media screen and (max-width: 700px) {
      .Exchange-content {
        background: #eff2f7 !important;
      }
    }
  }

  .view-chart {
    img {
      filter: brightness(0);
    }
  }

  .Exchange-swap-section {
    .TokenSelector-box {
      .TokenSelector-caret {
        // filter: brightness(0);

        path {
          fill: #000;
        }
      }
      &:hover {
        .TokenSelector-caret {
          // filter: brightness(0);

          path {
            fill: #02b27f;
          }
        }
      }
    }
  }

  .Exchange-trigger-order-info a {
    border: none;
  }

  .TokenSelector {
    .TokenSelector-token-input-row {
      background: var(--Background-Input_Background, rgba(38, 77, 65, 0.07));

      img {
        filter: brightness(0);
      }

      input {
        &::placeholder {
          color: rgba(13, 26, 22, 0.7);
        }
      }
    }
  }

  .positionHistory-modal {
    .divider {
      display: block;
      margin: 0;
    }
  }

  .exchange-v2 {
    .confirmation-modal {
      .Modal-body {
        border-top: none !important;

        .free-colateral {
          color: #0d1a16b2 !important;
          opacity: 1 !important;
        }
      }
    }

    .square-container-yellow {
      border-color: #02b27f;
    }

    .right-balance > span {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
    }

    .PositionSeller-modal.confirmation-modal {
      .Tab.block {
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

        .Tab-option {
          color: var(--Text-Text_Primary, rgba(13, 26, 22, 0.7)) !important;
          font-weight: 500 !important;

          &.active,
          &:hover {
            background: rgba(2, 178, 127, 0.15) !important;
          }
        }
      }
    }
  }

  .order-title-line {
    .title {
      color: #0d1a16;
    }
  }

  .allow-slippage-container {
    border: 1px solid var(--Primary, #02b27f);

    input {
      color: #02b27f !important;
    }

    div {
      color: #02b27f !important;
    }
  }

  .app-card-position {
    .leverage-pad {
      div {
        &:last-child {
          background: var(--Leverage-Background, #fef6d8) !important;
          color: #d69e03 !important;
        }
      }
    }
  }

  .trade-v1 {
    .Modal-content {
      .Exchange-swap-section {
        width: calc(100% - 3px);
        margin-left: 3px;
      }
    }
  }

  .chart-pad-open {
    background: #eff2f7 !important;
  }

  .pad-chart-container {
    border-top: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
    background: var(--Border, rgba(13, 26, 22, 0.07)) !important;
    backdrop-filter: blur(17px) !important;
  }

  .exchange-v1 {
    .PositionDropdown-extra-options {
      .menu-items {
        .menu-item {
          &:hover {
            background: var(--Border, rgba(13, 26, 22, 0.07)) !important;
          }
        }
      }
    }

    .Exchange-swap-box-inner.App-box-highlight.box-trade-v2 {
      .Exchange-swap-ball-icon {
        opacity: unset;
      }
    }

    .order-error {
      .Tooltip-handle {
        color: #d62f44 !important;
      }
    }
  }

  .isShort {
    background: var(--Functional-Red, #d62f44) !important;
  }

  .isLong {
    background: var(--Primary, #02b27f) !important;
  }

  .Exchange-updated-ui {
    .Tab {
      .Tab-option {
        color: rgba(13, 26, 22, 0.7) !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;

        &:hover {
          background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
        }
      }

      .active {
        background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
        color: #02b27f !important;
      }
    }

    .Exchange-swap-option-tabs {
      .short {
        color: #d62f44 !important;
      }

      .long {
        color: var(--Functional-Green, #05b681) !important;
      }
    }

    .isProfit {
      color: #02b27f !important;
    }

    .isLoss {
      color: #d62f44 !important;
    }

    .leverage-box {
      background: var(--Leverage-Background, #fef6d8) !important;
      color: var(--Leverage-Text, #d69e03) !important;
    }

    td {
      .Exchange-list-info-label {
        .negative {
          color: #d62f44 !important;
        }

        .positive {
          color: #02b27f !important;
        }
      }
    }

    .leverage-pad {
      .negative {
        color: #d62f44 !important;
      }

      .positive {
        color: #02b27f !important;
      }
    }
  }

  .swap-v1-container {
    .new-history {
      // a {
      //   img {
      //     filter: brightness(0) !important;
      //   }
      //   &:hover {
      //     img {
      //       filter: unset !important;
      //     }

      //   }
      // }
    }
    .Exchange-lists {
      @media screen and (max-width: 1350px) {
        background: none !important;
      }
    }

    .Exchange-content {
      @media screen and (max-width: 700px) {
        background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%) !important;
      }
    }

    .table-mobile {
      .open-position {
        border-top: 1px solid rgba(13, 26, 22, 0.07) !important;
      }

      .close-on {
        border-top: none !important;

        .size {
          &:last-child {
            margin-top: 4px;

            .label {
              color: rgba(13, 26, 22, 0.7) !important;
            }
          }
        }
      }
    }

    .table-line-info {
      @media screen and (max-width: 1350px) {
        padding: 0 !important;
        padding-bottom: 16px !important;
      }

      .market {
        .coin {
          color: #0d1a16 !important;
          font-weight: 400 !important;
        }
      }

      .type {
        color: #0d1a16 !important;
      }
    }
  }

  .red-value {
    color: #d62f44 !important;
  }

  .Checkbox-label {
    span {
      font-size: 14px;
    }
  }

  .leverage-box {
    background: var(--Leverage-Background, #fef6d8) !important;
    color: var(--Leverage-Text, #d69e03) !important;
  }

  .Exchange-list-action {
    span {
      color: #02b27f !important;

      &:hover {
        color: #03805b !important;
      }
    }

    &:is(button.border-btn) {
      span {
        color: #0d1a16 !important;
      }

      // &:hover {
      //   span {
      //     color: #03805b !important;
      //   }
      // }
    }
  }

  .table-line-info {
    background: white !important;

    @media (max-width: 1023px) {
      .child {
        &::before {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }

        &:first-child {
          border-bottom: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
        }
      }
    }

    .pos-long {
      color: #02b27f !important;
    }

    .pos-short {
      color: #d62f44 !important;
    }

    .action-child {
      color: #02b27f !important;

      &:hover {
        color: #02845e !important;
      }
    }
  }

  .exchange-v1 {
    .Modal-content {
      .Tab {
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

        .Tab-option {
          color: #0d1a16 !important;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background: rgba(13, 26, 22, 0.07) !important;
          }
        }

        .active {
          background: rgba(13, 26, 22, 0.07) !important;
        }
      }
    }
  }

  .Exchange-lists {
    .table {
      .header {
        .child {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }

      .table-line-info {
        .action-child {
          color: #02b27f !important;

          &:hover {
            color: #02845e !important;
          }
        }
      }
    }
  }

  .trade-v1 {
    .PositionDropdown-dots-icon {
      &:hover {
        path {
          color: #02b27f !important;
        }
      }
    }
  }

  .PositionDropdown-extra-options {
    .menu-items {
      box-shadow: 4px 0px 24px 0px rgba(0, 0, 0, 0.2) !important;
    }
  }

  .muted {
    opacity: 1;
    color: rgba(13, 26, 22, 0.7) !important;

    span {
      color: rgba(13, 26, 22, 0.7) !important;
    }

    div {
      color: rgba(13, 26, 22, 0.7) !important;
    }
  }

  .Exchange-list-header {
    th {
      opacity: 1 !important;

      span {
        color: rgba(13, 26, 22, 0.7) !important;
      }
    }
  }

  .Modal-content {
    form {
      input {
        border: 1px solid rgba(13, 26, 22, 0.07);
      }
    }
  }

  .Exchange-info-row.top-line {
    border-top: 1px solid rgba(13, 26, 22, 0.07);
  }

  .exchange-v2 .toogle-card-container .percentage-btn-selected {
    background: rgba(3, 245, 174, 0.1) !important;
    color: var(--Primary, #02b27f);
  }

  .exchange-v2 {
    .percentahe-value {
      border: 1px solid var(--Primary, #02b27f) !important;

      input {
        color: var(--Primary, #02b27f) !important;
      }

      &.init-value {
        border-color: #0d1a16 !important;

        & > input,
        & > span {
          color: #0d1a16 !important;
        }
      }

      span {
        color: var(--Primary, #02b27f) !important;
      }
    }
  }

  .Checkbox-icon-wrapper {
    .active {
      path {
        color: #02b27f !important;
      }
    }
  }

  .link-underline {
    &:hover {
      color: #02b27f !important;

      span:not(.position-head-symbol-symbol, .longOrShort, .leverage) {
        color: #02b27f !important;
      }
    }
  }

  .square-trade-v2 {
    .align-right {
      .TokenSelector-box {
        img {
          filter: brightness(0);
        }
      }
    }
  }

  .TokenSelector-token-row {
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

    .text-accent {
      color: rgba(13, 26, 22, 0.7) !important;
    }
  }

  .trade-v1 {
    .Exchange-right {
      @media screen and (max-width: 1350px) {
        background: #eff2f7 !important;
      }
    }
  }

  .PositionsList table .table-content {
    background: white;

    &:hover {
      background: white !important;
    }
  }

  .Exchange {
    .link-underline {
      &:hover {
        color: #02b27f !important;
      }
    }
  }

  .Modal-body {
    .Checkbox {
      .active {
        path {
          color: #02b27f !important;
        }
      }
    }
  }

  .price-token-styled {
    .icon-styled {
      img {
        filter: brightness(0);
      }
    }
  }

  .Exchange-list-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  .App-slippage-tolerance-input {
    // border-radius: var(--Base-Unit-S-12, 12px) !important;
    background: var(--Background-Input_Background, rgba(38, 77, 65, 0.07)) !important;
  }

  .chains-btn {
    border: 1px solid #02b27f !important;

    span {
      color: #02b27f !important;
    }

    &:hover {
      border: 1px solid #02845e !important;
    }
  }
}

.custom-popup-antd-tooltip-light {
  .ant-tooltip-inner {
    background: #5e6673 !important;
  }

  .ant-tooltip-arrow {
    &::before {
      background: #5e6673 !important;
    }
  }
}

.TokenSelector {
  @media screen and (max-width: 900px) {
    .Modal-body {
      margin: 0 !important;
    }
  }

  // .TokenSelector-box {
  //   &:hover {
  //     .PositionSelector-selected-receive-token {
  //       color: #02b27f !important;
  //     }
  //     color: #02b27f !important;
  //     svg {
  //       path {
  //         color: #02b27f !important;
  //       }
  //     }
  //   }
  // }
}

.allow-slippage-container {
  input {
    font-weight: 500;
  }
}

.square-trade-v2 {
  .TokenSelector-box {
    font-weight: 700 !important;
  }
}

.PositionDropdown-dots-icon {
  img {
    position: relative;
    top: -3.5px;

    @media screen and (max-width: 1350px) {
      top: -2px;
    }
  }
}
