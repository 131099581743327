.connect-wallet-btn {
  background: transparent;
  padding: 10px 16px;
  display: inline-flex;
  align-items: center;
  border: none;
  color: #fff;
  font-size: var(--font-sm);
  cursor: pointer;
  border: 1px solid #ffffff1a;
  border-radius: 4px;
  background: transparent;
  height: 4rem;
  .btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .btn-label {
    font-weight: 500;
    font-size: var(--font-sm);
    // margin-left: 0.8rem;
    margin-left: 0;
    letter-spacing: 0;
  }
}

@media (max-width: 450px) {
  .connect-wallet {
    padding: 0.5rem 0.8rem;
  }
}

@media (max-width: 350px) {
  .connect-wallet-btn {
    height: 3.6rem;
    .btn-icon {
      display: none;
    }
    .btn-label {
      margin-left: 0px;
    }
  }
}
