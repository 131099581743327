body:has(.App.theme--light) {
  &::-webkit-scrollbar {
    background: #0000001a;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #0000001a;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #00000099;
    border: 1px solid #00000099;
  }

  *::-webkit-scrollbar {
    background: #0000001a;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    background: #0000001a;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: #00000099;
    border: 1px solid #00000099;
  }

  .toastify-container {
    span {
      color: #000;
    }

    .status-icon-wrapper {
      &.success {
        path {
          fill: #02b27f;
        }
      }

      &.error {
        path {
          fill: #d62f44;
        }
      }

      &.info {
        path {
          fill: #366ae2;
        }
      }

      &.warn {
        path {
          fill: #d49007;
        }
      }
    }

    .toastify-content-wrapper {
      color: #0d1a16b2;

      a {
        color: #02b27f;

        span {
          color: #02b27f;
        }

        &:hover {
          color: #03805b;

          span {
            color: #03805b;
          }
        }
      }

      .connect-error-wrapper {
        .connect-error-content {
          color: #0d1a16b2;
        }

        .connect-error-switch {
          color: #02b27f;

          &:hover {
            color: #03805b;
          }
        }
      }
    }
  }

  .Toastify__close-button {
    path {
      fill: rgba(0, 0, 0, 0.6) !important;
    }
  }

  .Toastify__toast--info {
    background: linear-gradient(90deg, #b5cbff 0%, #ffffff 100%) !important;
  }

  .Toastify__toast--success {
    background: linear-gradient(90deg, #ccfff0 0%, #ffffff 100%) !important;
  }

  .Toastify__toast--warning {
    background: linear-gradient(90deg, #ffefce 0%, #ffffff 100%) !important;
  }

  .Toastify__toast--error {
    background: linear-gradient(90deg, #ffced5 0%, #ffffff 100%) !important;
  }

  .Toastify__progress-bar--info {
    background: #366ae2 !important;
  }

  .Toastify__progress-bar--success {
    background: #02b27f !important;
  }

  .Toastify__progress-bar--warning {
    background: #d49007 !important;
  }

  .Toastify__progress-bar--error {
    background: #d62f44 !important;
  }
}

.theme--light {
  .border-btn {
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
    color: #0d1a16 !important;

    &:hover {
      border: 1px solid #02b27f !important;
      color: #02b27f !important;
    }

    &:active {
      border: 1px solid #02b27f !important;
      color: #02b27f !important;
    }

    span:hover {
      color: #02b27f !important;
    }
  }
}

.App.theme--light {
  color: #0d1a16;
  background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%);

  * {
    color: #0d1a16;
  }

  .App-header-container-left {
    a.active {
      color: #02b27f !important;
    }

    a:hover {
      color: #02b27f !important;
    }
  }

  .App-header.large {
    border-color: var(--Border, rgba(13, 26, 22, 0.07));
  }

  .App-header-dropdown .menu-selected {
    color: var(--Primary, #02b27f) !important;

    path {
      fill: var(--Primary, #02b27f) !important;
    }
  }

  .App-header-user-address {
    &:not(:has(img[alt="Avatar"])) {
      border: 1px solid rgba(13, 26, 22, 0.07) !important;
    }

    &:hover {
      background: #fff !important;
    }

    .address-btn {
      background: transparent !important;
    }
  }

  .solid-btn {
    background: #f2f5f7;

    &:hover {
      background: #edeef0;

      .btn-label span {
        color: #000 !important;
      }
    }
  }

  .Tooltip-popup {
    box-shadow: 4px 0px 24px 0px rgba(0, 0, 0, 0.2);
    color: #fff !important;

    * {
      color: #fff !important;
    }
  }

  input {
    &::placeholder {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
    }
  }

  a.App-social-link {
    opacity: 1;
  }

  .border-btn {
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
    color: #0d1a16 !important;

    &:hover {
      border: 1px solid #02b27f !important;
      color: #02b27f !important;
    }

    &:active {
      border: 1px solid #02b27f !important;
      color: #02b27f !important;
    }

    span:hover {
      color: #02b27f !important;
    }
  }

  // .default-btn {
  //   background: var(--Primary, #02b27f);
  //   color: #fff !important;

  //   &>span {
  //     color: #fff !important;
  //   }

  //   &:not(:disabled):hover {
  //     background: #03805b !important;
  //   }

  //   &:not(:disabled):active {
  //     background: #03805b !important;
  //   }
  // }

  a.App-button-option:not(.border-btn),
  button.App-button-option:not(.border-btn),
  .App-cta {
    background: var(--Primary, #02b27f);
    color: #fff !important;

    span {
      color: #fff !important;
    }

    // &:disabled {
    //   background: #03805b !important;
    // }

    &:not(:disabled):hover {
      background: #03805b !important;
    }

    &:not(:disabled):active {
      background: #03805b !important;
    }
  }

  .short-btn-v2 {
    background: var(--Primary, #E43E53) !important;
    color: #fff !important;

    & > span {
      color: #fff !important;
    }

    &:hover {
      background: #aa2233 !important;
    }

    &:not(:disabled):hover {
      background: #aa2233 !important;
    }

    &:not(:disabled):active {
      background: #aa2233 !important;
    }
  }

  .short-btn-default {
    background: var(--Primary, #d62f44) !important;
    color: #fff !important;
    box-shadow: none !important;

    & > span {
      color: #fff !important;
    }

    &:hover {
      background: #aa2233 !important;
    }

    &:not(:disabled):hover {
      background: #aa2233 !important;
    }

    &:not(:disabled):active {
      background: #aa2233 !important;
    }
  }

  .Modal {
    .Modal-content {
      // background: #F2F5F7 !important;

      .Modal-body {
        border-top: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      }

      &:has(.box) {
        background: var(--Nature-2, #f2f5f7) !important;
      }

      & > .divider {
        border-color: var(--Border, rgba(13, 26, 22, 0.07));
      }
    }

    &.secondary {
      .Modal-content {
        background: var(--Nature-2, #f2f5f7) !important;
      }
    }
  }

  .Modal-content .Exchange-swap-section {
    background: var(--Background-Input_Background, rgba(38, 77, 65, 0.07)) !important;
  }

  .App-content {
    .Tooltip {
      .Tooltip-row > span.label,
      .Tooltip-row > .Tooltip-row-value {
        color: var(--Text-Text_Button, #fff) !important;
      }

      .Tooltip-popup {
        background: var(--Background-Tooltip_Background, #5e6673) !important;
        color: var(--Text-Text_Button, #fff) !important;

        .divider {
          border-color: #0d1a1612;
        }

        .k-v-box {
          .k-v-label {
            color: var(--Text-Text_Button, #fff) !important;

            .ver {
              border-color: #02b27f !important;
              color: #02b27f !important;

              &.v2 {
                border-color: #d49007 !important;
                color: #d49007 !important;
              }
            }
          }

          .k-v-value {
            color: var(--Text-Text_Button, #fff) !important;
          }
        }

        .chain-value {
          color: var(--Text-Text_Button, #fff) !important;
        }

        .Tooltip-row-value {
          color: #fff !important;
        }

        .Tooltip-row-values {
          li {
            color: #fff !important;
          }
        }
      }
    }

    .Tooltip:not(.excep) {
      .Tooltip-popup {
        * {
          color: #fff;
        }

        div {
          &:last-child {
            color: #fff !important;
          }
        }
      }
    }
  }

  .table-token-container {
    table {
      tbody {
        tr {
          td {
            background: #fff;
          }
        }
      }
    }
  }

  .ant-select {
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
    border-radius: 4px;

    .ant-select-selector {
      background-color: #fff !important;
    }
  }

  .ant-select-selection-item {
    color: #0d1a16 !important;
  }

  .ant-select-dropdown {
    background: #fff !important;
    width: unset;
  }

  .ant-select-item-option-content {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-content {
    color: #fff !important;
  }

  .react-datepicker {
    background-color: #fff !important;
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
    box-shadow: 0 2px 4px #0003;
  }

  .react-datepicker__header {
    background-color: #fff !important;
  }

  .react-datepicker__triangle::before {
    border-bottom-color: rgba(13, 26, 22, 0.07) !important;
    border-top-color: rgba(13, 26, 22, 0.07) !important;
  }

  .react-datepicker__triangle::after {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
  }

  .react-datepicker__day-names .react-datepicker__day-name,
  .react-datepicker__day-names .react-datepicker__day,
  .react-datepicker__week .react-datepicker__day-name,
  .react-datepicker__week .react-datepicker__day {
    color: #0d1a16 !important;
  }

  .no-data-record {
    background: #fff;
    border: 1px solid rgba(13, 26, 22, 0.07) !important;
  }

  .skeleton-box {
    background: #dadddc5e;
  }

  .skeleton-box::after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(202, 202, 202, 0.22) 20%,
      rgba(242, 248, 246, 0.15) 60%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .ExchangeChart-title {
    .chart-token-selector {
      &:hover {
        background: var(--HighLight, rgba(45, 144, 116, 0.15)) !important;
      }
    }
  }

  .no-data-card:not(.Exchange-empty-positions-list-note) {
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
  }

  .App-card {
    background: #fff;

    .App-card-row {
      & > .Tooltip .Tooltip-handle,
      .label,
      .label span,
      .label .Tooltip .Tooltip-handle {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
      }
    }
  }

  .validate-error {
    color: #d62f44 !important;
  }

  .filter-duration-wrapper {
    background-color: #fff !important;
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

    span {
      color: #0d1a16;
    }
  }

  .account-trade-history {
    th {
      color: rgba(13, 26, 22, 0.7) !important;
    }

    td {
      background-color: #fff;
    }
  }

  .liquididy-wrap {
    background: #fff;

    .liquidity-label {
      color: rgba(0, 0, 0, 0.6);
    }

    .info {
      color: #d49007;
    }
  }

  .profile {
    .name {
      color: #0d1a16 !important;
    }

    .title {
      color: rgba(13, 26, 22, 0.7) !important;
    }

    // @media (max-width: 1024px) {
    //   border-bottom: 1px solid rgba(13, 26, 22, 0.07) !important;
    // }
  }

  .custom-tooltip {
    background: var(--Background-Tooltip_Background, #5e6673) !important;

    .label-total {
      color: #fff;
    }
  }

  .movePage {
    .btn-primary {
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }

  .filter-block {
    color: rgba(13, 26, 22, 0.7) !important;
  }

  .back-url-styled {
    color: #02b27f !important;
    background: var(--Nature-2, #f2f5f7);

    path {
      fill: #0d1a16;
    }

    &:hover {
      color: #03805b !important;

      path {
        fill: #03805b !important;
        stroke: #03805b !important;
      }
    }
  }

  .react-datepicker {
    .react-datepicker__day--keyboard-selected {
      background-color: #02b27f !important;
      color: #fff !important;
    }
  }

  .txt-link {
    &:hover {
      path {
        fill: rgb(3, 128, 91) !important;
      }
    }
  }

  .vault-link {
    color: #02b27f !important;

    &:hover {
      color: rgb(3, 128, 91) !important;

      path {
        fill: rgb(3, 128, 91) !important;
      }
    }
  }

  .react-dropdown-select-dropdown {
    background: #fff !important;
    border: 1px solid #ccc !important;
  }
}
