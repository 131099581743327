.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.4 !important;
}

.App-header.large::after {
  height: 1.3rem;
  width: 100%;
  content: " ";

  position: absolute;
  bottom: -1.3rem;
  left: 0;
}

.App-header-balance-container {
  display: flex;
  height: 40px;
  padding: 0px 8px 0px 16px;
  align-items: center;
  gap: 8px;
  border-radius: var(--Border-Radius-Medium, 12px);
  background: var(--Nature-1, #18191a);
  &:hover {
    filter: brightness(1.5);
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
}

.App-header-eth-balance-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.App-header-add-eth {
  display: flex;
  width: 24px;
  height: 24px;
  padding: var(--Border_radius, 4px) var(--Base-Unit-XS-8, 8px);
  align-items: center;
  gap: var(--Base-Unit-XS-8, 8px);
  border-radius: 100px;
  background: var(--Button-Background, #7ee55c);
  outline: none;
  border: none;
  transition: all 150ms ease-in-out;

  &:hover {
    // background-color: #6be9ca;
  }
}

.App-header.large .App-header-links {
  display: flex;
}

.App-header.small {
  display: none;
}

.App-header-link-home {
  display: none;
}

.App-header-drawer {
  background: #17182c88 0% 0% no-repeat padding-box;
  box-shadow: 0.8rem 0.3rem 0.6rem #00000029;
  opacity: 1;
  backdrop-filter: blur(2.7rem);
  position: fixed;
  z-index: 801;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 30.4rem;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.App-header-drawer::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.App-header-drawer .App-header-link-container-menu {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 16px;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 0.5px solid #ffffff1a;

  &.selected {
    flex-direction: column;
    align-items: stretch;
    height: unset;
    gap: var(--base-unit-s-12, 12px);
    align-self: stretch;
    background: var(--v-3-natural-2, #20233b);

    .App-header-link-container {
      border-bottom: none;

      a {
        height: unset !important;
      }
    }

    > .App-header-link-row span {
      color: #1eb2fc;
    }
  }

  .App-header-link-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      color: #ffffff99;
      font-size: 14px;
    }
  }

  a {
    display: flex !important;
    padding: var(--base-unit-s-12, 12px) !important;
    align-items: center;
    gap: var(--base-unit-s-12, 12px);
    align-self: stretch;
    border-radius: var(--base-unit-s-12, 12px);

    &.active {
      background: var(--v-3-natural-1, #191b2e) !important;
    }

    img {
      display: flex;
      padding: var(--base-unit-xs-8, 8px);
      align-items: flex-start;
      gap: 10px;
      border-radius: var(--base-unit-s-12, 12px);
      background: var(--white-10, rgba(255, 255, 255, 0.1));
    }

    .wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Border_radius, 4px);
      flex: 1 0 0;

      span {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%;
        /* 19.6px */

        &.tag-v2 {
          display: inline-flex;
          padding: 2px var(--Border_radius, 4px);
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: var(--Border_radius, 4px);
          border: 1px solid var(--L3, #f0b90b);
          text-align: center;
          font-size: 10px;
          font-weight: 800;
          line-height: 100%;
          text-transform: capitalize;
          color: #f0b90b;
        }

        &.desc {
          color: var(--white-60, rgba(255, 255, 255, 0.6));
          font-weight: 400;
        }
      }
    }
  }
}

.App-header-item-container {
  padding: 16px;
  border-bottom: 0.5px solid #ffffff1a;
}

.App-header-balance-container--mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 8px 0 16px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #18191a;

  .App-header-balance {
    display: flex;
    align-items: center;
  }

  .App-header-mobile-left {
    display: flex;
    align-items: center;
  }

  .App-header-usd-balance {
    margin-right: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .user-avatar {
    margin-right: 8px;
  }
}

.App-header-eth-balance-container {
  display: flex;
  align-items: center;
  gap: 4px;

  @media screen and (max-width: 1023px) {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    padding-left: 4px;
  }
}

.App-header-eth-icon {
  display: flex;
}

.App-header-add-eth {
  display: flex;
  width: 24px;
  height: 24px;
  padding: var(--Border_radius, 4px) var(--Base-Unit-XS-8, 8px);
  align-items: center;
  gap: var(--Base-Unit-XS-8, 8px);
  border-radius: 100px;
  background: var(--Button-Background, #1eb2fc);
  outline: none;
  border: none;
  transition: all 150ms ease-in-out;

  &:hover {
    background: #4787e3;
  }
}

.App-header-drawer .App-header-link-container {
  border-bottom: 0.5px solid #ffffff1a;

  a {
    font-weight: 500 !important;
  }

  &:has(.disabled) {
    cursor: not-allowed;
    .ymy-item {
      opacity: 0.5;
    }
  }

  // &:first-child {
  //   border-top: 0.5px solid var(--nature-3, #232121);
  // }
}

.App-header-drawer .App-header-link-container .a,
.App-header-drawer .App-header-link-container a {
  font-size: var(--font-sm);
  line-height: 1.8rem;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #a0a3c4;
  padding: 1.5rem 1.6rem;
  text-decoration: none;
  display: block;
}

.App-header-drawer .App-header-link-container .a:hover,
.App-header-drawer .App-header-link-container .a:focus,
.App-header-drawer .App-header-link-container .a:active,
.App-header-drawer .App-header-link-container a:hover,
.App-header-drawer .App-header-link-container a:focus,
.App-header-drawer .App-header-link-container a.active {
  background: #303fd024;
  color: white;
}

.App-header-links-header {
  height: 6.2rem;
  display: flex;
  align-items: center;
  z-index: 3;
}
.App-header-link-container {
  @media screen and (max-width: 1460px) {
    a {
      padding: 1.8rem 1.15rem !important;
    }
    .App-header-dropdown {
      padding: 0 1.15rem !important;
    }
  }
}
.App-header-container-left {
  display: flex;
  align-items: center;
}

.App-header-top {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  /* z-index: 2; */
}

.App-header-menu-icon-block {
  margin-right: 1.2rem;
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.App-header-menu-icon {
  color: white;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 0.9rem 1rem;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

.App-header a.App-header-link-main,
.App-header .App-header-link-main {
  font-size: 1.7rem;
  padding: 1.5rem;
  margin-left: -1.5rem;
  display: flex;
  margin-right: 1.2rem;
}

.App-header-link-main img {
  display: inline-block;
  height: 2.15rem;
  vertical-align: middle;
}

.App-header-link-main img.small {
  display: none;
}

.App-header .a,
.App-header a {
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}

.App-header .a.active,
.App-header a.active {
  opacity: 1;
  color: #eee;
}

// .App-header .a.active:not(.clickable),
// .App-header a.active:not(.clickable) {
//   pointer-events: none;
// }

.App-header .a:hover,
.App-header a:hover {
  opacity: 1;
  color: #eee;
}

.App-header-container-left .a,
.App-header-container-left a {
  padding: var(--Base-Unit-XS-8, 8px) 16px;
  display: flex;
  color: #848e9c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.App-header-container-left .a.active,
.App-header-container-left a.active {
  color: white;
}

.App-header-container-right {
  display: flex;
  align-items: center;
}

.App-header-user {
  display: flex;
  align-items: center;
}

.App-header-user-settings {
  display: inline-flex;
  cursor: pointer;
  border: none;
  border-radius: 1.5rem;
  font-size: var(--font-base);
  background: none;
  color: white;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  align-items: center;
  border-radius: 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.App-header-user-netowork {
  margin-right: 2.4rem;
}

.App-header-user-link {
  margin-right: 2.4rem;
}

.App-header-user-address {
  gap: var(--Border_radius, 4px);

  background: var(--Nature-1, #18191a);

  position: relative;
  border: 1px solid #2b3139;
}

@media (max-width: 1350px) {
  .App-header-user-address {
    padding: 0 0px;
  }

  .App-header-user-link {
    margin-right: 1.6rem;
  }

  .App-header-user-netowork {
    margin-right: 1.6rem;
  }

  .App-header-link-home {
    display: block;
  }

  .App-header-user {
    padding-bottom: 0;
  }

  .App-header-container-right {
    display: flex;
    align-items: center;
  }

  .App-header-link-desktop {
    display: none;
  }

  .App-header {
    grid-template-columns: 1fr auto auto auto auto;
  }

  .App-header-link-main img {
    height: 2.1rem;
  }

  .App-header.large {
    display: none;
  }

  .App-header.small {
    height: 6.2rem;
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: space-between;
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    background: transparent 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #ffffff10;
  }

  .App-header.small::after {
    height: 1.3rem;
    width: 100%;
    content: " ";
    background: transparent linear-gradient(180deg, #00000030 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    bottom: -1.3rem;
    left: 0;
  }

  a.App-header-user-account {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .App-header-link-main img.big {
    display: none;
  }

  .App-header-link-main img.small {
    display: inline-block;
  }
}

@media (max-width: 450px) {
  .App-header-user-link {
    margin-right: 0.6rem;
  }

  .App-header-user-netowork {
    margin-right: 0.6rem;
  }

  .App-header-menu-icon-block {
    margin-left: 0;
    margin-right: 0.4rem;
  }

  .App-header-menu-icon {
    margin: 0.4rem 0.5rem;
  }

  .App-header.small {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }

  .App-header .App-header-link-main {
    padding: 0.155rem !important;
    margin-left: 0rem !important;
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .App-header-drawer {
    background: #0e0f1f;
  }
}

.header-dropdown {
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  display: inline-block;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    width: 100% !important;
  }
}

.header-dropdown-content {
  display: none;
  position: absolute;
  top: 50px;
  z-index: 1;

  min-width: 170px;

  background: #16182e;
  // border: 1px solid #32344c;
  box-shadow: 0px 4px 12px #32344c;
  border-radius: 4px;
  padding: 12px;

  @media screen and (max-width: 1024px) {
    min-width: none;
    // padding: 0;
    width: 100%;
  }
}

.header-dropdown-content a {
  padding: 8px !important;
  text-decoration: none;
  display: block;
  color: white;
}

.header-dropdown-content a:hover {
  color: white !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 4px;
}

.header-dropdown:hover .header-dropdown-content {
  display: block;
}

.header-dropdown:hover .header-dropdown-content a {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  .ic-menu-destop {
    background: #ff4d4f;
    border-radius: 4px;
    padding: 8px;
    height: fit-content;
    margin-right: 12px;
  }

  .menu-desc {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.6);
  }

  @media screen and (max-width: 1024px) {
    .ic-menu-destop,
    .menu-desc {
      display: none;
    }
  }
}

.dropbtn {
  .dropdown-img {
    filter: opacity(0.5);
  }

  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0a3c4;
  border: none;

  &:hover {
    color: white;
  }

  @media screen and (max-width: 1033px) {
    padding: 1.5rem 1.6rem;
    width: 100%;
  }
}

.ymy-item {
  display: flex !important;
  padding: var(--Base-Unit-XS-8, 8px) var(--Base-Unit-S-12, 12px);
  align-items: center;
  gap: var(--Border_radius, 4px);
}

.more-ymy {
  color: #fff;
}
