.Referrals {
  // max-width: 100% !important;

  // min-height: 1000px !important;

  // margin-bottom: 120px !important;
  padding-bottom: 286px !important;
  background: linear-gradient(180deg, #0a1633 0%, #010206 100%) !important;
  .top-bg-container {
    background: linear-gradient(180deg, #0a1633 0%, #010206 100%) !important;
  }
  .section-title-block {
    display: flex;
    justify-content: center;

    background-repeat: no-repeat;
    background-size: cover;

    padding-top: 36px;
    margin-bottom: 0;

    max-width: 100%;

    width: 100%;
    height: 289px;
    @media (max-width: 1350px) {
      margin-bottom: 0 !important;
    }
    @media (max-width: 768px) {
      height: 300px;
    }

    @media (max-width: 600px) {
      height: 290px;

      .right {
        margin-top: 20px;
      }
    }

    @media (max-width: 767px) {
      padding-top: 30.5px;
    }

    .section-title-content {
      width: 1320px;

      flex-direction: row;
      justify-content: space-between;

      .right {
        width: 376px;
        height: 225px;

        align-self: flex-end;
      }

      .Page-title {
        font-size: 36px;
        margin-bottom: 4px;

        @media (max-width: 1100px) {
          font-size: 24px;
        }
      }

      @media (max-width: 1320px) {
        padding-left: 32px;
        padding-right: 32px;
      }

      @media (max-width: 1100px) {
        height: 271px;

        padding-left: 24px;
        padding-right: 24px;

        .right {
          width: 267px;
        }
      }

      @media (max-width: 700px) {
        flex-direction: column;

        padding-left: 16px;
        padding-right: 16px;

        .right {
          display: none;
          width: 200px;
          align-self: flex-start;
        }
      }
    }
  }

  .referral-tab-container {
    margin: 0;

    display: flex;
    justify-content: center;

    .container {
      width: 1320px;

      @media (max-width: 1320px) {
        padding-left: 32px;
        padding-right: 32px;
      }

      @media (max-width: 1024px) {
        padding-left: 24px;
        padding-right: 24px;
        margin-top: -1.5rem;
      }

      @media (max-width: 600px) {
        padding-left: 16px;
        padding-right: 16px;
        margin-top: -1.5rem;
      }

      .Tab {
        width: fit-content;
        border-radius: 0 0 16px 16px;

        .Tab-option {
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;

          color: #ffffff;

          &.active {
            background: rgba(255, 255, 255, 0.1);

            color: #1eb2fc;
          }
        }
      }
    }
  }

  .container-ref {
    margin-top: 36px;

    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      margin-top: 24px;
    }

    .content-ref {
      width: 1320px;

      display: flex;
      gap: 23px;

      @media (max-width: 1320px) {
        padding-left: 32px;
        padding-right: 32px;
      }

      @media (max-width: 1024px) {
        padding-left: 24px;
        padding-right: 24px;
      }

      @media (max-width: 900px) {
        flex-direction: column;
        margin-bottom: 120px;

        .section-center {
          max-width: 100% !important;
          width: 100% !important;
        }
      }

      @media (max-width: 600px) {
        padding-left: 16px;
        padding-right: 16px;
        gap: 16px;
      }

      .section-center {
        margin: 0;
        height: fit-content;

        background: radial-gradient(50% 285.34% at 100% 0%, rgba(0, 46, 88, 0.8) 0%, rgba(25, 27, 46, 0.8) 100%);

        .top-title {
          text-align: left;

          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: rgba(255, 255, 255, 0.6);

          margin-bottom: 4px;
        }

        .title {
          text-align: left;

          padding: 0;
        }

        .sub-title {
          text-align: left;

          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: rgba(255, 255, 255, 0.6);

          margin: 16px 0 24px 0;
        }

        .line {
          position: absolute;

          margin-left: -24px;
          width: 100%;
          height: 1px;

          background: rgba(255, 255, 255, 0.1);
        }

        .your-code-rate {
          margin-top: 48px;
          margin-bottom: 0;

          text-align: left;

          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: rgba(255, 255, 255, 0.6);
        }

        .your-code-percentage {
          margin: 4px 0;

          text-align: left;

          font-weight: 700;
          font-size: 24px;
          line-height: 140%;

          color: #ffffff;

          @media (max-width: 600px) {
            font-size: 16px;
          }
        }

        .your-code-tier {
          margin-bottom: 24px;

          text-align: left;

          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: rgba(255, 255, 255, 0.6);
        }

        .modal-ref-code {
          cursor: pointer;

          display: flex;
          justify-content: center;
          align-items: center;

          height: 40px;

          font-weight: 500;
          font-size: 14px;
          line-height: 140%;

          color: #080715;

          background: #1eb2fc;
          border-radius: 4px;
        }
      }

      .rebate-container {
        flex: 1;

        .info-card {
          justify-content: unset;
          padding: 24px;

          .card-details {
            h3 {
              margin-bottom: 0;

              font-weight: 400;
              font-size: 14px;
              line-height: 140%;

              color: rgba(255, 255, 255, 0.6);
            }

            .data {
              text-align: left;

              margin: 4px 0 0 0;

              font-weight: 700;
              font-size: 24px;
              line-height: 140%;

              color: #ffffff;

              @media (max-width: 600px) {
                font-size: 16px;
              }
            }
          }
        }

        .reward-history {
          margin: 0;

          .title {
            margin: 36px 0 16px 0;

            font-weight: 700;
            font-size: 24px;
            line-height: 140%;

            color: #ffffff;
          }

          .header {
            display: flex;

            padding: 0 16px;

            .child {
              flex: 1;

              font-weight: 400;
              font-size: 14px;
              line-height: 140%;

              color: rgba(255, 255, 255, 0.7);
            }
          }

          .content {
            display: flex;

            padding: 16px;
            margin-top: 8px;

            background: linear-gradient(90deg, #212224 0%, #212224 100%);
            border-radius: 4px;

            .child {
              flex: 1;

              font-weight: 400;
              font-size: 14px;
              line-height: 140%;

              color: #ffffff;

              a {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;

                color: #ffffff !important;
              }
            }

            &.mobile {
              flex-direction: column;
              gap: 12px;

              .mobile-wrapper {
                display: flex;
                justify-content: space-between;
                line-height: 20px;

                a {
                  margin-right: 0;
                  color: #ffffff;
                }
              }
            }
          }

          .empty-message {
            margin-top: 20px;
          }
        }
      }

      .referral-body-container {
        flex: 1;

        .info-card {
          justify-content: unset;
          padding: 24px;

          .card-details {
            h3 {
              margin-bottom: 0;

              font-weight: 400;
              font-size: 14px;
              line-height: 140%;

              color: rgba(255, 255, 255, 0.6);
            }

            .data {
              text-align: left;

              margin: 4px 0 0 0;

              font-weight: 700;
              font-size: 24px;
              line-height: 140%;

              color: #ffffff;
            }
          }
        }

        .reward-history {
          margin: 0;

          .title {
            margin: 36px 0 16px 0;

            font-weight: 700;
            font-size: 24px;
            line-height: 140%;

            color: #ffffff;
          }

          .header {
            display: flex;

            padding: 0 16px;

            .child {
              flex: 1;

              font-weight: 400;
              font-size: 14px;
              line-height: 140%;

              color: rgba(255, 255, 255, 0.7);

              &.no-header {
                flex: 0.6;

                display: flex;
                justify-content: flex-end;
              }
            }
          }

          .content {
            display: flex;

            padding: 16px;
            margin-top: 8px;

            background: linear-gradient(90deg, #212224 0%, #212224 100%);
            border-radius: 4px;

            .child {
              display: flex;
              align-items: center;

              flex: 1;

              font-weight: 400;
              font-size: 14px;
              line-height: 140%;

              color: #ffffff;

              a {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;

                color: #ffffff !important;
              }

              &.no-header {
                flex: 0.6;

                display: flex;
                justify-content: flex-end;
              }
            }

            &.mobile {
              flex-direction: column;
              gap: 12px;

              .mobile-wrapper {
                display: flex;
                justify-content: space-between;
                line-height: 20px;

                a {
                  margin-right: 0;
                  color: #ffffff;
                }
              }
            }
          }

          .empty-message {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.more-info-container {
  margin-top: 24px;

  .earn {
    span {
      color: #1eb2fc;
    }
  }

  .ref-code-container {
    margin-top: 8px;
    padding: 0px;
    width: fit-content !important;
    // border: 1px solid white;
    background-image: linear-gradient(#212224 0%, #212224 43.8%, #212224 68.83%, #212224 92.41%),
      linear-gradient(90deg, #ff4fed 0%, #5bffc5 50%, #4adc9f 100%);
    border: 1px solid transparent;
    border-radius: 4px;
    background-origin: border-box;
    background-clip: content-box, border-box;

    .ref-content {
      padding: 12px;
      display: flex;
      align-items: center;
    }

    img {
      cursor: pointer;
      pointer-events: all !important;
      margin-left: 4px;
    }
  }
}

.ref-stats-container {
  margin-top: 48px;
  margin-bottom: 284px;
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  // min-height: 100vh;

  @media screen and (max-width: 1320px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 228px;

    .ref-as-content {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 700px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .ref-as-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .ref-as-content {
      width: 424px;
      display: flex;
      flex-direction: column;
      padding: 24px;
      border-radius: 16px;
      background: linear-gradient(116deg, #18235d 1.03%, #212224 100%);

      button {
        margin-top: 16px;
      }

      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        margin-bottom: 8px;
      }

      .ref-desc {
        margin-top: 16px;
        color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
        line-height: 140%;
      }

      .ref-as-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;

        .line-title {
          color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
        }

        .line-value {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;

          svg {
            position: relative;
            top: 3px;
            cursor: pointer;
            margin-left: 4px;

            &:hover {
              path {
                fill: #1eb2fc;
              }
            }
          }

          .tier {
            font-weight: 400 !important;
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
  }

  .right-content-container {
    height: fit-content;
    flex: 1;
  }

  .payout-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: fit-content;
    align-items: center;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      button {
        display: none !important;
      }
    }

    .payout-tab-container {
      display: flex;
      align-items: center;
      border-radius: var(--Base-Unit-XS-8, 8px);
      background: var(--Border_Highlight, rgba(255, 255, 255, 0.1));
      border: none;

      .payout-tab {
        border-radius: var(--Base-Unit-XS-8, 8px);
        padding: 12px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        cursor: pointer;
        border: none;

        &:hover {
          background: var(--Border_Highlight, rgba(255, 255, 255, 0.1));
        }
      }

      .payout-tab-active {
        background: var(--Border_Highlight, rgba(255, 255, 255, 0.1));
        // color: var(--Primary, #1EB2FC);
      }
    }
  }
}

.payout-content-container {
  margin-top: 24px;

  .table-content {
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;

    .line-content {
      flex: 1;
    }
  }

  .table-actions {
    width: 74px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    .ic_container {
      border-radius: var(--Base-Unit-XS-8, 8px);
      padding: var(--Border_radius, 4px);
      width: 32px;
      height: 32px;

      &:hover {
        background: #4c4d4e;
      }

      cursor: pointer;

      img {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  .table-header {
    .line-content {
      color: #919499;
    }
  }

  .table-body {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .table-content {
      border-radius: 4px;
      background: #212224;
      padding: 10px 16px;
    }

    a {
      text-decoration: underline;
      cursor: pointer;
      color: white !important;
    }
  }
}

.mobile-ref-container {
  gap: 12px;
  display: flex;
  flex-direction: column;

  .ref-as-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    border-radius: 4px;
    background: var(--Nature-1, #212224);

    .ref-as-line {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line-title {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}

.tab-payout-container {
  .table-body {
    .table-content {
      padding: 16px !important;
      height: 52px;
    }

    .line-content {
      line-height: 140%;
    }
  }
}

.coppy-container {
  padding: 3px;
  border-radius: 4px;
  margin-left: 4px;

  img {
    margin-left: 0 !important;
    width: 22px;
    height: 22px;
  }

  &:hover {
    background: #3a3e59;
  }
}

.theme--light {
  .Referrals {
    background: #eff2f7 !important;

    .section-title-block {
      .section-title-content {
        color: white;

        .Page-title {
          span {
            color: white;
          }
        }

        .Page-description {
          div,
          a {
            color: rgba(255, 255, 255, 0.6);
          }
          a {
            &:hover {
              color: white !important;
            }
          }
        }
      }

      .more-info-container {
        .earn {
          color: white;
          span {
            color: #1eb2fc;
          }
        }

        .ref-code-container {
          .ref-content {
            color: white;
          }
        }
      }
    }

    .ref-as-container {
      .ref-as-content {
        background: linear-gradient(139deg, rgba(50, 164, 239, 0.2) 8.79%, rgba(165, 219, 250, 0) 100%) !important;
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
        .line-title {
          color: rgba(13, 26, 22, 0.7) !important;
        }

        .line-value {
          .tier {
            color: rgba(13, 26, 22, 0.7);
          }
          svg {
            path {
              fill: #010206;
            }
            &:hover {
              path {
                fill: #02b27f;
              }
            }
          }
        }

        .ref-desc {
          color: rgba(13, 26, 22, 0.7) !important;
        }
      }
    }
    .right-content-container {
      .payout-tab-container {
        .payout-tab {
          background: transparent !important;
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
          &:hover {
            background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
          }
        }
        .payout-tab-active {
          background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
          color: var(--Primary, #02b27f) !important;
        }
      }
    }
    .main-content-container {
      .table-header {
        .line-content {
          color: rgba(13, 26, 22, 0.7) !important;
        }
      }
      .table-body {
        .table-content {
          background: white;
          a {
            color: #0d1a16 !important;
          }
        }
      }
    }
    .table-actions {
      .ic_container {
        background: #f2f5f7;
        &:hover {
          background: #dddede;
        }
      }
      a {
        img {
          filter: brightness(0);
        }
      }
    }
    .mobile-ref-container {
      .ref-as-container {
        background: white;
        .line-title {
          color: rgba(13, 26, 22, 0.7);
        }
      }
    }
  }
}
