html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 10px;
  background-color: #000;
}

html {
  height: unset;
  scroll-behavior: smooth;
}

#root::before {
  content: "";
  position: fixed;
  inset: 0;
  z-index: 0;
  background: #000;
  /* background-image: url("../img/background.png");
  background-repeat: no-repeat;
  background-size: cover; */
}

#root {
  overflow-y: overlay;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter", sans-serif;
}

* {
  box-sizing: border-box;
  font-family: "Inter";
}
*::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0.05);
  width: 2px;
  height: 2px;
}
/* Track */
*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  width: 2px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: var(--ScrollBar-Handle, rgba(255, 255, 255, 0.6));
  width: 2px;
}

:root {
  --dark-blue: #17182c;
  --dark-blue-bg: #16182e;
  --dark-blue-accent: #a0a3c4;
  --dark-blue-hover: #808aff14;
  --dark-blue-border: #ffffff29;
  --dark-blue-active: #818cef33;
  --avax-red: #e84143;
  --arbitrum-blue: #28a0f0;
  --border-radius-sm: 0.4rem;
  --error-red: #fa3c58;
  --font-sm: 1.4rem;
  --font-base: 1.4rem;
  --font-md: 1.6rem;
  --font-lg: 2.4rem;
  --font-xl: 3.4rem;
}

a {
  color: white;
}

.Card-title {
  font-size: 3.1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  font-size: 2rem;
  color: white;
  outline: none;
  background: none;
  border: none;
  padding: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Inter";
}

button {
  outline: none;
  cursor: pointer;
}

button:disabled,
button.App-cta:disabled {
  cursor: not-allowed;
  opacity: 0.9;
  box-shadow: none;
}

table {
  margin: 0;
  padding: 0;
}

tr {
  margin: 0;
  padding: 0;
}

td {
  margin: 0;
  padding: 0;
  padding-right: 0.465rem;
}

.border {
  border: 1px solid rgba(163, 165, 196, 0.2);
}

.align-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.muted {
  font-weight: 500;
  color: #848e9c;
  /* margin-left: 4px; */
}

.muted-color {
  color: #848e9c;
  /* margin-left: 4px; */
}

.muted.Tab-option {
  margin-left: 0;
}

.strong {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.text-none {
  text-transform: none;
}

.relative {
  position: relative;
}

.margin-bottom {
  margin-bottom: 0.465rem;
}

.transition-arrow {
  vertical-align: middle;
  margin-top: -0.31rem;
}

.inline-block {
  display: inline-block;
}

.text-white {
  color: white;
}

.label,
.label a {
  color: #848e9c;
}

ul {
  padding-left: 2.325rem;
  margin-bottom: 0;
}

ul li {
  padding-bottom: 1.5rem;
}

a.plain {
  text-decoration: none;
}

.checkbox:checked:before {
  background-color: green;
}

button.bold {
  font-weight: 700 !important;
}

.default-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1eb2fc;
  border-radius: 4px !important;
  font-size: 14px;
  line-height: 140%;
  font-weight: 700;
  letter-spacing: 0;
  color: #ffffff;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 3.6rem;
  display: inline-flex !important;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  height: 40px;
  padding: 8px 16px;
  gap: 4px;
}
.default-btn:hover {
  background: #4787e3;
}
.default-btn.full,
.border-btn.full {
  width: 100%;
}

.default-btn:not(:disabled):hover,
.default-btn1:not(:disabled):hover {
  background: #3388ff;
  color: #ffffff !important;
}

/* .default-btn:focus {
  background: #515ed8;
  border: 1px solid #3D4FFE;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
} */

.default-btn:active,
.default-btn1:active {
  background: #9ccf79;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
}

.default-btn:active::after,
.default-btn1:active::after {
  opacity: 0.239;
  /* content: " "; */
  width: 5.2rem;
  height: 5.2rem;
  position: absolute;
  left: calc(50% - 2.6rem);
  top: -0.8rem;
  background: white;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
}

.default-btn:disabled,
.default-btn1:disabled {
  /* background: #2d2e3f 0% 0% no-repeat padding-box; */
  /* color: #ffffff61; */
  color: #545655 !important;
  cursor: no-drop;
  background: #aeaeae !important;
}

.default-btn.disabled,
.border-btn.disabled,
.default-btn1.disabled {
  background-color: #aeaeae !important;
  cursor: no-drop;
  color: #545655 !important;
}

.default-container {
  max-width: 1368px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.secondary-btn {
  background: #03d1cf;
  border-radius: 4px;
  font-size: var(--font-sm);
  line-height: 2rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: black;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  /* padding: 9px 16px!important; */
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 3.6rem;
  border: none;
  display: inline-flex !important;
  align-items: center;
  text-align: center;
}

.secondary-btn:hover {
  background: #3fdad9;
}

.secondary-btn:focus {
  background: #54dedd;
  border: 1px solid #1cb5b4fc;
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.secondary-btn:active {
  background: #54dedd;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  /* padding: 9px 16px; */
}

.secondary-btn:active::after {
  opacity: 0.239;
  content: " ";
  width: 5.2rem;
  height: 5.2rem;
  position: absolute;
  left: calc(50% - 2.6rem);
  top: -0.8rem;
  background: white;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 500ms linear;
}

.secondary-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box;
  color: #ffffff61;
}

@keyframes ripple {
  to {
    transform: scale(2);
    opacity: 0.4;
  }
}

button.btn-link {
  margin-top: 1.5rem;
  background: none;
  color: white;
  border: none;
  padding: 0;
  font-size: var(--font-base);
  display: block;
  text-align: left;
}

.logout-icon {
  vertical-align: middle;
  font-size: 1.7rem;
  margin-top: -0.31rem;
  margin-right: 0.5rem;
  margin-left: -0.465rem;
  opacity: 0.8;
}

button.btn-link:hover {
  text-decoration: underline;
}

button.btn-link:hover .logout-icon {
  opacity: 1;
}

.page-container {
  padding-top: 6.2rem;
}

.default-sm-container {
  max-width: 108.8rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  /* padding-bottom: calc(4.65rem + 20rem); */
  padding-bottom: 286px;
  padding-top: 64px;
}

@media (max-width: 767px) {
  .page-layout {
    padding-top: 30.5px;
  }
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.font-sm {
  font-size: var(--font-sm);
}
.font-base {
  font-size: var(--font-base);
}
.mr-base {
  margin-right: 1.5rem;
}
.default-cursor.App-cta {
  cursor: default;
}
img {
  pointer-events: none;
}

.ant-select-dropdown {
  background: #2e3033;
  border-radius: 4px;
  padding: 8px;
  width: unset !important;
}

.ant-select-dropdown .ant-select-item-option {
  padding: 6px 12px;
  border-radius: var(--Border-Radius-Medium, 8px);
}

.ant-select-item-option-content {
  color: #848e9c;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #1eb2fc;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-content {
  color: #fff;
  font-weight: 500 !important;
}

.downIc {
  transform: rotate(180deg);
}

.ant-select-dropdown.css-dev-only-do-not-override-ixblex.ant-select-dropdown-placement-bottomLeft {
  width: fit-content !important;
}
.ant-select-selector .ant-select-selection-item {
  color: #fff !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent;
}

.ant-select:not(.ant-select-customize-input).hasBorder .ant-select-selector {
  border-color: #ffffff29 !important;
}

/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #191b2e;
}

::-webkit-scrollbar-thumb {
  background: #ffffff1a;
  border: none;
} */
.validate-error {
  font-size: 14px;
  color: #e43e53 !important;
  margin: 8px 0;
  display: block;
}
.WrongNetworkModal .Modal-content {
  max-width: 424px;
}
.WrongNetworkModal .Modal-title-bar {
  margin: 16px 24px;
}
.WrongNetworkModal .Modal-title {
  color: var(--white-100, #fff);
  font-size: 20px;
  font-weight: 700;
  line-height: 140%; /* 28px */
}
.WrongNetworkModal > .Modal-content > .Modal-body {
  padding: 16px 24px 24px !important;
}
/* .Exchange-left::-webkit-scrollbar {
  width: 0px !important;
} */
.ant-select-dropdown.theme--light {
  background: #fff !important;
}
.ant-select-dropdown.theme--light .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background: rgba(13, 26, 22, 0.07) !important;
}
.ant-select-dropdown.theme--light
  .ant-select-item.ant-select-item-option.ant-select-item-option-selected
  .ant-select-item-option-content {
  color: #0d1a16 !important;
  font-weight: 500 !important;
}
.ant-select-dropdown.theme--light .ant-select-item.ant-select-item-option:not(.ant-select-item-option-selected):hover {
  /* background: var(--Nature-2, #f2f5f7) !important; */
  background: rgba(2, 178, 127, 0.15) !important;
}
.ant-select-dropdown.theme--light .ant-select-item.ant-select-item-option .ant-select-item-option-content {
  color: var(--Text-Text_Primary, #0d1a16) !important;
}
/* .ant-select-dropdown.theme--light .ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background: #02b27f !important;
} */

.exchange-v2 .Exchange-right::-webkit-scrollbar {
  width: 0px !important;
}
.exchange-v2 .Exchange-right::-webkit-scrollbar-track {
  width: 0px !important;
}
.exchange-v2 .Exchange-right::-webkit-scrollbar-thumb {
  width: 0px !important;
}
.default-btn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1eb2fc !important;
  border-radius: 4px !important;
  font-size: 14px;
  line-height: 140%;
  font-weight: 700;
  letter-spacing: 0;
  color: #fff !important;
  padding: 8px 12px;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 3.6rem;
  display: inline-flex !important;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  height: 40px;
}
.ant-select-dropdown .ant-select-item:hover {
  background: #212224;
}
.ant-popover-content {
}

.ant-popover-inner {
  padding: 8px !important;

  border-radius: 4px !important;
  background-color: #1a1d26 !important;

  /* Shadow_Tooltip */
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25) !important;
}
/* .ant-select-dropdown {
  padding: 0;
} */
.ant-select-selector {
  box-shadow: none !important;
}
